import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import constants from './mixins/constants'
import filters from './mixins/filters'
import i18n from '@/lang'
import VueGtag from "vue-gtag";
import * as moment from 'moment-timezone';




import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

require('@/assets/sass/main.scss')
require('@/assets/sass/plan.scss')




import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaperPlane, faEdit, faUserEdit, faThumbtack, faBan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faPaperPlane)
library.add(faEdit)
library.add(faUserEdit)
library.add(faThumbtack)
library.add(faBan)

import 'vue-awesome/icons/flag'




const app = createApp(App)
app.config.globalProperties.$constants = constants
app.config.globalProperties.$filters = filters
app.config.globalProperties.$moment = moment

app.component('fa', FontAwesomeIcon )
import 'v-calendar/dist/style.css';


app
.use(router)
.use(store)
.use(PrimeVue, {
    ripple: true,
})
.use(ToastService)
.use(ConfirmationService)
.use(i18n)
.use(VueGtag, {
      config: { id: process.env.VUE_APP_G_ID }
})
.mount('#app');
