import http from '../mixins/api'
export default {
    namespaced: true,
    state: {
        form: {
            components: []
        },
        FormStatus: null,
        FormId: null,
        SendDate: null,
        IsRead: false,
        userAttrList: [],
        allUserAttrList: [],
    },
    getters: {
        ButtonTextStatus(state) {
            if(state.FormStatus == 1) {
                return "Sent"
            } else if(state.FormStatus == 2 || state.FormStatus == 4 ) {
                return "Accepted"
            } else if(state.FormStatus == 3 || state.FormStatus == 5 ) {
                return "Rejected"
            } else if(state.FormStatus == 6) {
                return "AlreadyWithdrawal"
            }
        },

        getFormStatus(state) {
            return state.FormStatus
        },

        getDocumentName(state) {
            return state.form.DocumentName
        },

        getDepartment(state) {
            return state.form.Department
        },

        getDepartmentName(state) {
            return state.form.DepartmentName
        },

        getUserAttr(state) {
            return state.form.UserAttr
        },

        getUserAttrList(state) {
            return state.userAttrList || []
        },

        getAppTypeList(state) {
            return state.form.AppTypeList || []
        },

        getAppType(state) {
            return state.form.AppType || []
        },

        getSendDate(state) {
            return state.SendDate
        },

        getComponents(state) {
            return state.form.components
        },

        getRecord: (state) => (id) => {
            return state.recordList.find(record => record.ComponentFieldId == id);
        },

        getFormId(state) {
            return state.FormId
        },

        getFromEmail(state) {
            return state.FromEmail
        },
        getSenderFormNote(state) {
            return state.form.SenderFormNote
        },
        getRead(state) {
            return state.IsRead
        },
    },
    mutations: {
        setFromEmail(state, FromEmail) {
            state.FromEmail = FromEmail
        },
        setRecordList(state, recordList) {
            state.recordList = recordList
        },
        setDocumentName(state, DocumentName) {
            state.form.DocumentName = DocumentName
        },
        setDepartmentName(state, DepartmentName) {
            state.form.DepartmentName = DepartmentName
        },
        setDepartment(state, Department) {
            state.form.Department = Department
        },
        setUserAttr(state, userAttr) {
            state.form.UserAttr = userAttr
        },
        setAppTypeList(state, appTypeList) {
            state.form.AppTypeList = appTypeList
        },
        setAppType(state, appType) {
            state.form.AppType = appType
        },
        setUserAttrList(state, userList) {
            state.userAttrList = userList
        },
        setComponents(state, components) {
            state.form.components.splice(0, state.form.components.length, ...components)
        },
        setStatus(state, Status) {
            state.FormStatus = Status
        },
        clear(state) {
            state.form = {
                components: []
            }
            state.FormStatus = null
        },
        setFormId(state, formId) {
            state.FormId = formId
        },
        setSenderFormNote(state, SenderFormNote) {
            state.form.SenderFormNote = SenderFormNote
        },
        setRead(state, IsRead) {
            state.IsRead = IsRead
        },
        setAllUserAttrData(state, userAttrList) {
            state.allUserAttrList.splice(0, state.allUserAttrList.length, ...userAttrList)
        },
        setUserAttrData(state, language) {
            const allUserAttrList = state.allUserAttrList
            const userAttrList = allUserAttrList.filter(x => x.language == language)
            state.userAttrList.splice(0, state.userAttrList.length, ...userAttrList)
        },
    },
    actions: {
        async getFormById (context, params) {
            const FormId = params.FormId
            const UUID = params.UUID
            const res = await http.post("/form/ConfirmPostedFormUUID", {"FormId": FormId, UUID: UUID})
            if(res.Success) {
                context.commit('setDocumentName', res.Data.DocumentName);
                context.commit('setDepartment', res.Data.Department);
                context.commit('setDepartmentName', res.Data.DepartmentName);
                context.commit('setUserAttr', res.UserAttrValue);
                context.commit('setAppType', res.Data.AppType);
                context.commit('setAppTypeList', res.AppTypeList);
                context.commit('setStatus', res.FormStatus);

                context.commit('setComponents', res.Data.components);
                context.commit('setFormId', res.FormId);
                context.commit('setFromEmail', res.FromEmail);
                context.commit('setSenderFormNote', res.SenderFormNote);
                context.commit('setRead', res.IsRead);
                context.commit('setAllUserAttrData', res.allUserAttrList)

            }

            return res
        },

        async getSenderFormNoteById (context, params) {
            const FormId = params.FormId
            const res = await http.post("/form/GetSenderFormNoteById", {"FormId": FormId})
            if(res.Success) {
                context.commit('setSenderFormNote', res.SenderFormNote);
            }

            return res
        },

        async updateSenderFormNoteById (context, Note) {
            const FormId = context.state.FormId
            console.log(context.state)
            const res = await http.post("/form/UpdateSenderFormNoteById", {"FormId": FormId, "Note": Note})
            if(res.Success) {
                context.commit('setSenderFormNote', Note);
            }

            return res
        },
    }
}
