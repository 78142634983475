import http from '../mixins/api'

export default {
    namespaced: true,
    state: {
        templateIdList: []
    },

    getters: {
        getJoinedTemplateIdList(state) {
            return state.templateIdList
        },

    },

    mutations: {
        addJoinedTemplateIdList(state, templateId) {
            state.templateIdList.push(templateId)
        },

        removeJoinedTemplateIdList(state, templateId) {
            const index = state.templateIdList.findIndex(x => x == templateId)
            if (index != -1) {
                state.templateIdList.splice(index, 1)
            }
        },

        joinTemplate(state, params) {
            const socket = params.socket
            const templateId = params.templateId
            
            socket.emit('joinByTemplateId', templateId)

            socket.on('disconnect', (data) => {
                socket.emit('joinByTemplateId', templateId)
            });
        },


        leaveByALLTemplateId(state, params) {
            const socket = params.socket
            const templateIdList = state.templateIdList
            for (let i = 0; i < templateIdList.length; i++) {
                const templateId = templateIdList[i]
                socket.emit('leave', templateId)
            }
        },

        leaveByTemplateId(state, params) {
            const socket = params.socket
            const templateId = params.templateId
            socket.emit('leave', templateId)
        },


    },

    actions: {
    },
}

