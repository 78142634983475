import http from '../mixins/api'

export default {
    namespaced: true,
    state: {
        DepartmentId: "",
        ValidList: [],
        IsValidFormUrl: false,
        fireCallbackFunction: () => {},
        fireCallbackFunctionParams: {}
    },

    getters: {
        getDepartmentId(state) {
            return state.DepartmentId
        },
        getValidFormUrl(state) {
            return state.IsValidFormUrl
        },
        getValidList(state) {
            return state.ValidList
        },
    },

    mutations: {
        setDepartmentId(state, DepartmentId) {
            state.DepartmentId = DepartmentId
        },
        setValidFormUrl(state, valid) {
            state.IsValidFormUrl = valid
        },
        setValidList(state, ValidList) {
            state.ValidList = ValidList
        },
        setFireCallbackFunction(state, callback) {
            state.fireCallbackFunction = callback
        }
    },

    actions: {
        async getValidateList({commit, state}, params) {
            const DepartmentId = state.DepartmentId
            const res = await http.post("/plan/GetValidateList", {DepartmentId: DepartmentId})
            console.log(res)
            if(res.Success) {
                const ValidFunctionList = res.ValidFunctionList
                commit("setValidList", ValidFunctionList)
            }

            return res;
        },

        async checkValidFunction({commit, state}, params) {
            const validFunc = params.validFunc
            const DepartmentIdList = params.DepartmentIdList
            const res = await http.post("/plan/CheckValidFunction", {validFunc: validFunc, DepartmentIdList: DepartmentIdList})

            if(!res.Success) {
                commit("setActivePlanAlertText", res.Message)
                commit("setActivePlanAlert", true)
            }
            return res

        },

        async fireCallbackFunction({commit, state}, params) {
            await state.fireCallbackFunction(state.fireCallbackFunctionParams)
        }

    },
}
