import isMobile from 'ismobilejs'

export default {
    isAny: function() {
        if (isMobile().any) {
            return true;
        }
        return false;
    },
    isMobile: function() {
        // return true
        if (isMobile().phone) {
            return true;
        }
        return false;
    },
    isTablet: function() {
        if (isMobile().tablet) {
            return true;
        }
        return false;
    }
}
