import http from '../mixins/api'
import i18n from '@/lang'

export default {
    namespaced: true,
    state: {
        language: "",
        languageList: [
            {
                "name": "日本語",
                "value": "ja"
            },
            {
                "name": "English",
                "value": "en"
            },
        ],
        isPassedBeforeForm: false,
        isPassedBeforeFormMessage: false,
    },

    getters: {
        getUserLanguage(state) {
            return state.language
        },

        getUserLanguageList(state) {
            return state.languageList
        },

        isPassedBeforeForm(state) {
            return state.isPassedBeforeForm
        },
        isPassedBeforeFormMessage(state) {
            return state.isPassedBeforeFormMessage
        },
    },

    mutations: {
        setUserLanguage(state, language) {
            i18n.global.locale = language;
            state.language = language
        },

        setInitUserLanguage(state) {
            // const language = navigator.language.split('-')[0] ? navigator.language.split('-')[0] : i18n.global.locale;
            const language = i18n.global.locale;
            console.log(language)
            state.language = language
        },

        setIsPassedBeforeForm(state, isPassedBeforeForm) {
            state.isPassedBeforeForm = isPassedBeforeForm
        },
        setIsPassedBeforeFormMessage(state, isPassedBeforeFormMessage) {
            state.isPassedBeforeFormMessage = isPassedBeforeFormMessage
        },
    },

    actions: {
    },
}
