import constants from '@/mixins/constants'
import store from '@/store'
import http from '@/mixins/api'
import { io } from "socket.io-client";
import i18n from '@/lang'
import router from '@/router'

let socket 

export default { 
    name: 'socketHelper',
    connect() {
        socket = io(constants.baseUrl, {'recoonect': true, 'transports':['websocket'], pingTimeout: 1000});
        // socket = io("https://192.168.128.32:50000", {'recoonect': true, 'transports':['websocket'], pingTimeout: 1000});
        socket.on('disconnect', (data) => {
            this.join()
        });
    },

    disconnect() {
        socket.on('disconnect')
    },

    join() {
        const userInfo = store.getters["auth/getUserInfo"]
        if(userInfo && userInfo._id) {
            // alert('join')
            socket.emit('join', userInfo._id)
        }
    },

    joinForm(formId) {
        socket.emit('joinForm', formId)
    },

    joinTemplate(templateId) {
        store.commit('socketStore/joinTemplate', {socket, templateId})
    },

    joinPreviewTemplate(uuid, callback) {
        socket = io(constants.baseUrl, {'recoonect': true, 'transports':['websocket'], pingTimeout: 1000});
        socket.emit('join', uuid)
        callback()
    },

    leaveForm(formId) {
        socket.emit('leave', formId)
    },

    leaveTemplate(templateId) {
        store.commit('socketStore/leaveByTemplateId', {socket, templateId})
    },

    leaveByALLTemplateId() {
        store.commit('socketStore/leaveByALLTemplateId', {socket})
    },

    setOn() {
        socket.on('CSVUpgradeConfirm', function(data) {
            store.commit('upgradePopup/setTextList', data.MessageList.map(x => { return {text: x} }))
            store.commit('upgradePopup/setTitle', i18n.global.tc("utils.error"))
            store.commit('upgradePopup/setShow', true)
        })

        socket.on('NotifingChangedSentFormNote', function(data) {
            const activeStatus = store.getters["gridDataStore/getActiveStatus"]
            if(activeStatus["Type"] == "Sent") {
                store.dispatch('gridDataStore/updateSentFormRow', {id: data._id})
            }
        })

        socket.on('VerificationUpgrade', function(data) {
            store.commit('upgradePopup/setTextList', data.MessageList.map(x => { return {text: x} }))
            store.commit('upgradePopup/setTitle', i18n.global.tc("utils.isUpgrade"))
            store.commit('upgradePopup/setShow', true)
        })

        socket.on('Hello', function(data) {
        })


        socket.on('NotifingChangeStatusFormTemplateSuccess', async function(data) {
            if (localStorage.getItem('TABUUID') == data.TABUUID) return
            const templateId = data.templateId
            const selectedRowId = store.getters['getSelectRowId']
            const route = router.currentRoute.value
            if (selectedRowId && templateId == selectedRowId) {
                const params = {
                    ...route.params,
                }
                delete params.templateFormId
                if (route.meta.name == "draft") {
                    store.commit('toastStore/setText', i18n.global.tc('socketAuto.autoChangedStatus'))
                } else {
                    store.commit('toastStore/setText', i18n.global.tc('socketAuto.autoChangedStatus2Confirm'))
                }
                store.commit('toastStore/setShow', true)
                store.commit('gridDataStore/setEditableForm', false)
                store.commit("editTemplate/clear")
                await store.dispatch('gridDataStore/reloadGridDataList')
                router.push({name: route.meta.name, params: params})

                return
            } else {
                const templateFormList = store.getters["gridDataStore/getActiveGridData"]
                if (templateFormList.length && templateFormList.find(x => x._id == templateId)) {
                    await store.dispatch('gridDataStore/reloadGridDataList')
                }
            }
        })

        socket.on('NotifingChangeStatusFormTemplateByInsertSuccess', async function(data) {
            if (localStorage.getItem('TABUUID') == data.TABUUID) return
            const templateId = data.templateId
            const selectedRowId = store.getters['getSelectRowId']
            const route = router.currentRoute.value
            const activeStatusInfo = store.getters["gridDataStore/getActiveStatus"]
            if (selectedRowId && templateId == selectedRowId) {
                const params = {
                    ...route.params,
                }
                delete params.templateFormId
                if (route.meta.name == "draft") {
                    store.commit('toastStore/setText', i18n.global.tc('socketAuto.autoChangedStatus'))
                } else {
                    store.commit('toastStore/setText', i18n.global.tc('socketAuto.autoChangedStatus2Confirm'))
                }
                store.commit('toastStore/setShow', true)
                store.commit('gridDataStore/setEditableForm', false)
                store.commit("editTemplate/clear")
                await store.dispatch('gridDataStore/reloadGridDataList')
                router.push({name: route.meta.name, params: params})

                return
            } else if (activeStatusInfo.Status == data.templateStatus) {
                const templateFormList = store.getters["gridDataStore/getActiveGridData"]
                await store.dispatch('gridDataStore/reloadGridDataList')
            }
        })
        
        socket.on('NotifingMoved2StatusFormSuccess', async function(data) {
            if (localStorage.getItem('TABUUID') === data.TABUUID) return
            const movedFormId = data.movedFormId
            const move2FormStatus = data.move2FormStatus 
            const move2DetailFormStatusId = data.move2DetailFormStatusId
            const selectedRowId = store.getters['getSelectRowId']
            const activeStatusInfo = store.getters["gridDataStore/getActiveStatus"]
            const route = router.currentRoute.value
            const detailFormStatusId = route.params.detailFormStatusId == constants.DetailFormStatusIdConstants ? "" : route.params.detailFormStatusId

            if (selectedRowId && movedFormId == selectedRowId) {
                const params = {
                    ...route.params,
                }
                delete params.formId
                store.commit('toastStore/setText', i18n.global.tc('socketAuto.autoChangedStatus'))
                store.commit('toastStore/setShow', true)
                store.commit('gridDataStore/setEditableForm', false)
                store.commit("viewPostedForm/clear")
                await store.dispatch('gridDataStore/reloadGridDataList')
                router.push({name: route.meta.name, params: params})

                return 
            } else if (
                ((activeStatusInfo.Status == move2FormStatus && route.params.detailFormStatusId == constants.DetailFormStatusIdConstants ) || 
                    (activeStatusInfo.Status == move2FormStatus && detailFormStatusId == move2DetailFormStatusId)) &&
                activeStatusInfo && activeStatusInfo.Type == "Form" 
            ) {   // 移動先にいたver
                await store.dispatch('gridDataStore/reloadGridDataList')

                return
            } else {            // 移動元にいたver
                const formList = store.getters["gridDataStore/getActiveGridData"]
                if (formList.length && formList.find(x => x._id == movedFormId)) {
                    await store.dispatch('gridDataStore/reloadGridDataList')

                    return
                }
            }
        })
        
        socket.on('NotifingMoved2StatusFormListSuccess', async function(data) {
            if (localStorage.getItem('TABUUID') == data.TABUUID) return
            const selectedRowId = store.getters['getSelectRowId']
            const movedFormIdList = data.info.map(x => x.movedFormId)
            const route = router.currentRoute.value
            if (movedFormIdList.includes(selectedRowId)) {
                const params = {
                    ...route.params,
                }
                delete params.formId
                store.commit('toastStore/setText', i18n.global.tc('socketAuto.autoChangedStatus'))
                store.commit('toastStore/setShow', true)
                store.commit('gridDataStore/setEditableForm', false)
                store.commit("viewPostedForm/clear")
                await store.dispatch('gridDataStore/reloadGridDataList')
                router.push({name: route.meta.name, params: params})

                return 

            }
            for (let i = 0; i < data.info.length; i++) {
                const movedFormId = data.info[i].movedFormId
                const move2FormStatus = data.info[i].move2FormStatus 
                const move2DetailFormStatusId = data.info[i].move2DetailFormStatusId
                const selectedRowId = store.getters['getSelectRowId']
                const activeStatusInfo = store.getters["gridDataStore/getActiveStatus"]
                const detailFormStatusId = route.params.detailFormStatusId == constants.DetailFormStatusIdConstants ? "" : route.params.detailFormStatusId

                if (route.params && detailFormStatusId == move2DetailFormStatusId &&
                    activeStatusInfo && activeStatusInfo.Type == "Form" && 
                    activeStatusInfo.Status == move2FormStatus) {   // 移動先にいたver
                    const formList = store.getters["gridDataStore/getActiveGridData"]
                    await store.dispatch('gridDataStore/reloadGridDataList')

                    break
                } else {            // 移動元にいたver
                    const formList = store.getters["gridDataStore/getActiveGridData"]
                    if (formList.length && formList.find(x => String(x._id) == movedFormId)) {
                        await store.dispatch('gridDataStore/reloadGridDataList')

                        break
                    }
                }
            }
        })

        socket.on('GetIsEditingTemplateByPreviewView', async function(data) {
            const templateId = data.templateId
            const previewSetOnUUID = data.previewSetOnUUID
            const selectedRowId = store.getters['getSelectRowId']
            if (selectedRowId && templateId == selectedRowId) {
                if (store.getters["editTemplate/getEdited"]) {
                    const res = await http.post("/form/NotifyIsEditingTemplateByPreviewView", {"templateId": templateId, previewSetOnUUID: previewSetOnUUID})
                    console.log(res)
                }
            }

        })

    },

    previewSetOn(UUID) {
        socket.on('NotifyIsEditingTemplateByPreviewView', async function(data) {
            const route = router.currentRoute.value
            console.log(data)
            if (route.name == "previewWindow" && route.query.id == data.templateId && UUID == data.previewSetOnUUID) {
                store.commit('previewFormStore/setEditingConfirmAlert', true)
            }
        })

    },


    emit(path, params) {
        socket.emit(path, params)
    },
}

