import http from '../mixins/api'

export default {
    namespaced: true,
    state: {
        languageList: [
            {
                label: "English",
                value: "en"
            },
            {
                label: "日本語",
                value: "ja"
            },
        ],
        activeLanguage: {
            value: "",
            label: "",
        },
    },

    getters: {
        getLanguageValue(state) {
            return state.activeLanguage.value
        },

        getActiveLanguage(state) {
            return state.activeLanguage
        },


        getLanguageList(state) {
            return state.languageList
        }
    },

    mutations: {
        setLanguageByValue(state, value) {
            const lang = state.languageList.find(x => x.value == value)
            state.activeLanguage = lang
        },

        setLanguage(state, self) {
            self.$i18n.locale = state.activeLanguage.value
        },
    },

    actions: {
        async updateSetting({context, state}, value) {
            const res = await http.post("/user/UpdateSetting", {language: {value: value}})
            return res
        },

        async getSetting({commit}, autoUpdate=true) {
            const res = await http.post("/user/GetSetting")
            if(res.Success && autoUpdate) {
                commit("setLanguageByValue", res.language)
            }
            return res
        }
    },
}

