import http from '../mixins/api'

export default {
    namespaced: true,
    state: {
        TemplateId: "",
        Message: "",
        isONMessage: false,
    },

    getters: {
        getTemplateId(state) {
            return state.TemplateId
        },
        getMessage(state) {
            return state.Message
        },
        getIsONMessage(state) {
            return state.isONMessage
        },
    },

    mutations: {
        setTemplateId(state, TemplateId) {
            state.TemplateId = TemplateId
        },
        setMessage(state, Message) {
            state.Message = Message
        },
        setIsONMessage(state, isONMessage) {
            state.isONMessage = isONMessage
        },
        clear(state) {
            state.TemplateId = ""
            state.Message = ""
            state.isONMessage = false
        },
    },

    actions: {
        async getMessageAfterSendingForm({commit, state}, params) {
            const res = await http.post("/form/GetMessageAfterSendingForm", {TemplateId: state.TemplateId})
            if(res.Success) {
                commit('setMessage', res.Message)
                commit('setIsONMessage', res.IsShow)
            }

            return res;
        },

        async updateMessageAfterSendingForm({commit, state}, params) {
            console.log(state.Message, state.isONMessage)
            const res = await http.post("/form/UpdateMessageAfterSendingForm", {TemplateId: state.TemplateId, Message: state.Message, IsShow: state.isONMessage})
            if(res.Success) {
            }

            return res;
        },
    },
}


