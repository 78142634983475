import i18n from '@/lang'
import constants from '@/mixins/constants'

export default {
    namespaced: true,
    state: {
        visibleBottomSidebarText: "",
        visibleBottomSidebar: false,
    },

    getters: {
        getVisibleBottomSidebarText(state) {
            return state.visibleBottomSidebarText
        },

        getVisibleBottomSidebar(state) {
            return state.visibleBottomSidebar
        },
    },

    mutations: {
        setVisibleBottomSidebar(state, value) {
            state.visibleBottomSidebar = value
        },
        setVisibleBottomSidebarText(state, text) {
            state.visibleBottomSidebarText = text
        },
    },

    actions: {
    },
}

