import http from '../mixins/api'
import { uuid } from "vue-uuid";

export default {
    namespaced: true,
    state: {
        TemplateId: "",
        FormTemplateShowBefore: {},
        FormTemplateShowMessageBeforeList: [],
        FormTemplateShowSelectboxBeforeList: [],
    },

    getters: {
        getTemplateId(state) {
            return state.TemplateId
        },
        getFormTemplateShowBefore(state) {
            return state.FormTemplateShowBefore
        },
        getFormTemplateShowMessageBeforeList(state) {
            return state.FormTemplateShowMessageBeforeList
        },
        getFormTemplateShowSelectboxBeforeList(state) {
            return state.FormTemplateShowSelectboxBeforeList
        },
        getFormTemplateShowBeforeIsActive(state) {
            return state.FormTemplateShowBefore.IsActive
        },
        getFormTemplateShowBeforeIsAuthActive(state) {
            return state.FormTemplateShowBefore.IsAuthActive
        },
        getFormTemplateShowBeforeIsSelectBoxActive(state) {
            return state.FormTemplateShowBefore.IsSelectBoxActive
        },
    },

    mutations: {
        setTemplateId(state, TemplateId) {
            state.TemplateId = TemplateId
        },
        setFormTemplateShowBefore(state, FormTemplateShowBefore) {
            state.FormTemplateShowBefore = FormTemplateShowBefore
        },
        setFormTemplateShowMessageBeforeList(state, FormTemplateShowMessageBeforeList) {
            state.FormTemplateShowMessageBeforeList = FormTemplateShowMessageBeforeList
        },
        setFormTemplateShowSelectboxBeforeList(state, FormTemplateShowSelectboxBeforeList) {
            state.FormTemplateShowSelectboxBeforeList = FormTemplateShowSelectboxBeforeList
        },
        addFormTemplateShowSelectboxBeforeList(state) {
            state.FormTemplateShowSelectboxBeforeList.push({
                SelectBoxText: "",
                SelectBoxValue: uuid.v4(),
                IndexInSelectboxList: state.FormTemplateShowSelectboxBeforeList.length - 1,
            })
        },
        minusFormTemplateShowSelectboxBeforeList(state) {
            state.FormTemplateShowSelectboxBeforeList.splice(state.FormTemplateShowSelectboxBeforeList.length - 1, 1)
        },
        setFormTemplateShowBeforeIsActive(state, IsActive) {
            state.FormTemplateShowBefore.IsActive = IsActive
        },
        setFormTemplateShowBeforeIsAuthActive(state, IsActive) {
            state.FormTemplateShowBefore.IsAuthActive = IsActive
        },
        setFormTemplateShowBeforeIsSelectBoxActive(state, value) {
            state.FormTemplateShowBefore.IsSelectBoxActive = value
        },
        clear(state) {
            state.TemplateId = ""
            state.FormTemplateShowBefore = {}
            state.FormTemplateShowMessageBeforeList = []
            state.FormTemplateShowSelectboxBeforeList = []
        },
    },
    

    actions: {
        async getFormTemplateShowBefore({commit, state}, params) {
            const res = await http.post("/form/GetFormTemplateShowBefore", {templateId: state.TemplateId})
            console.log(res)
            if(res.Success) {
                commit('setFormTemplateShowBefore', res.FormTemplateShowBefore)
                commit('setFormTemplateShowMessageBeforeList', res.FormTemplateShowMessageBeforeList)
                commit('setFormTemplateShowSelectboxBeforeList', res.FormTemplateShowSelectboxBeforeList)
            }

            return res;
        },

        async setFormTemplateShowBeforeMesasge({commit, state}, params) {
            const res = await http.post("/form/SetFormTemplateShowBefore", {
                templateId: state.TemplateId,
                FormTemplateShowBefore: state.FormTemplateShowBefore,
                FormTemplateShowMessageBeforeList: state.FormTemplateShowMessageBeforeList,
                FormTemplateShowSelectboxBeforeList: state.FormTemplateShowSelectboxBeforeList
            })
            if(res.Success) {
            }

            return res
        },
    },
}
