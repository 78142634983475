import http from '../mixins/api'

export default {
    namespaced: true,
    state: {
        detailFormStatusList: [],
        lastSelectedFormStatusList: [],
        lastOperationDetailFormIdInfo: {},
        lastBulkOperationDetailFormIdInfo: {},
    },
    getters: {
        getDetailFormStatusList(state) {
            return state.detailFormStatusList
        },
        getLastSelectedFormStatusList(state) {
            return state.lastSelectedFormStatusList
        },
        getLastOperationDetailFormIdInfo(state) {
            return state.lastOperationDetailFormIdInfo
        },
        getLastBulkOperationDetailFormIdInfo(state) {
            return state.lastBulkOperationDetailFormIdInfo
        },
    },
    mutations: {
        setDetailFormStatusList(state, detailFormStatusList) {
            state.detailFormStatusList = detailFormStatusList
        },
        setLastSelectedFormStatusList(state, params) {
            const activeRouteCode = params.activeRouteCode
            const departmentId = params.departmentId
            const selectedDetailFormStatusId = params.selectedDetailFormStatusId
            const index = state.lastSelectedFormStatusList.findIndex(x => x.departmentId  == departmentId && x.activeRouteCode == activeRouteCode)
            if(index != -1) {
                state.lastSelectedFormStatusList[index] = {
                    departmentId: departmentId,
                    activeRouteCode: activeRouteCode,
                    selectedDetailFormStatusId: selectedDetailFormStatusId
                }
            } else {
                state.lastSelectedFormStatusList.push({
                    departmentId: departmentId,
                    activeRouteCode: activeRouteCode,
                    selectedDetailFormStatusId: selectedDetailFormStatusId
                })
            }
        },

        setLastOperationDetailFormIdInfo(state, params) {
            if(state.lastOperationDetailFormIdInfo[params["departmentId"]]){
                state.lastOperationDetailFormIdInfo[params["departmentId"]][params["status"]] = params["detailFormStatusId"]
            } else {
                state.lastOperationDetailFormIdInfo[params["departmentId"]] = {
                    [params["status"]]: params["detailFormStatusId"]
                }
            }
        },

        setLastBulkOperationDetailFormIdInfo(state, params) {
            if(state.lastBulkOperationDetailFormIdInfo[params["departmentId"]]){
                state.lastBulkOperationDetailFormIdInfo[params["departmentId"]][params["status"]] = params["detailFormStatusId"]
            } else {
                state.lastBulkOperationDetailFormIdInfo[params["departmentId"]] = {
                    [params["status"]]: params["detailFormStatusId"]
                }
            }
        },

    },
    actions: {
        async getDetailFormStatusList ({context, state}, DepartmentId) {
            const res = await http.post("/form/GetDetailFormStatusList", {DepartmentId: DepartmentId})

            return res
        },

        async setDetailFormStatusList ({commit, state}, params) {
            const res = await http.post("/form/GetDetailFormStatusList", {DepartmentId: params.DepartmentId})
            if(res.Success) {
                const route = params.route
                if(route) {
                    const DetailFormStatusList = res.DetailFormStatusList.filter(x => x.ParentDetailFormStatusId == route._id)
                    commit("setDetailFormStatusList", DetailFormStatusList)
                } else {
                    commit("setDetailFormStatusList", [])
                }
            } else {
                commit("setDetailFormStatusList", [])
            }

            return res
        },
    }
}
