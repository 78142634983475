import constants from './constants'

export default {
    name: 'helper',
    data() {
    },
    copyTextToClipboard: function(text) {
        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copying text command was ' + msg);
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    },

    copyImage64ToClipboard: function(src) {
        var imgs = document.createElement('img');
        imgs.src = src;

        var bodys = document.body ;
        bodys.appendChild(imgs);

        if (document.createRange) {
            var myrange = document.createRange();
            myrange.setStartBefore(imgs);
            myrange.setEndAfter(imgs);
            myrange.selectNode(imgs);
        } else {
            alert ('CreateRange NOT work');
        }

        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(myrange);

        var successful = document.execCommand('copy');

        var msg = successful ? 'successful' : 'unsuccessful';
        bodys.removeChild(imgs);
    },


    checkExt: function (filename) {
        var pos = filename.lastIndexOf('.');
        if (pos === -1) return true;
        const ext =  filename.slice(pos + 1).toLowerCase()
        if (constants.validUploadFileExtList.indexOf(ext) === -1) return false;
        return true;
    },

    checkFileNameLen: function (filename) {
        return true

        if(filename){
            if(filename.length <= constants.maxFileNameLength){
                return true
            }

        }
        return false
    },

    focusLastElm: function(el) {
        el.focus()
        if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
            var range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
        }
    },
    getBrowserLanguage: function() {
        var language = (window.navigator.languages && window.navigator.languages[0]) ||
            window.navigator.language ||
            window.navigator.userLanguage ||
            window.navigator.browserLanguage || "en";

        return language
    },


    trimToURL: function (excludes, url){

        const newURL = new URL(url)
        const params = new URLSearchParams(newURL.search.substring(1));

        for(let i = 0; i < excludes.length; i++) {
            params.delete(excludes[i])
        }

        const trimedURL = newURL.origin + newURL.pathname + "?" + params.toString()

        return trimedURL;









        var builder = { search: '', hash: '' }, baseUrl = url; /* builderオブジェクトを用意 */

        if ( !url ) return url;

        if ( typeof(URL) === "function" ) {
            var builder = new URL(url);
            baseUrl = builder.origin + builder.port + builder.pathname;
        }
        else {
            var tmpURL = url.split('?'), search = '', num = '', hash = '';
            if ( tmpURL[1] !== undefined ) {
                baseUrl = tmpURL[0]; 
                search = tmpURL[1]; 
                num = url.indexOf('#'); 
                hash = (num > -1) ? url.substr(num) : "";
                search = '?' + search.replace(hash, "");
                builder = { 
                    search: search,
                    hash: hash,
                };
            }
        }

        if (builder.search) {
            builder.search = "?" + builder.search.substr(1).split('&').filter(function (item) {
                return !excludes.hasOwnProperty(item.split('=', 2)[0]);
            }).join('&');
            builder.search = ( builder.search.search(/\?$/) < 0 ) ? builder.search : ""; 
            return baseUrl + builder.search + builder.hash;
        }
        else {
            return url;
        }
    },

    getDataListFromClipboard: function(event) {
        var clipboardData = window.clipboardData || event.clipboardData || event.originalEvent && event.originalEvent.clipboardData;
        if (!clipboardData) {
            return {
                Success: false,
            }
        }
        console.log(clipboardData)
        var pastedText = clipboardData.getData("Text") || clipboardData.getData("text/plain");
        if (clipboardData.types.indexOf('text/plain') > -1) {
            return {
                Success: true,
                DataType: "text",
                Data: pastedText
            }
        } else {
            const files = []
            var items = event.clipboardData.items;
            for (var i = 0 ; i < items.length ; i++) {
                var item = items[i];
                if (item.kind == "file" && item.type) {
                    var file = item.getAsFile();
                    files.push(file)
                }
            }
            return {
                Success: true,
                DataType: "file",
                Data: files
            }
        }
        //
        // if (!pastedText && pastedText.length) {
        //     return;
        // }
        // var rows = pastedText.replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/mg, function (match, p1) {
        //     return p1
        //         .replace(/""/g, '"')
        //         .replace(/\r\n|\n\r|\n|\r/g, ' ')
        //     ;
        // })
        // .split(/\r\n|\n\r|\n|\r/g);
        // if (rows.length == 1 && rows[0] == '') {
        //     const files = []
        //     var items = event.clipboardData.items;
        //     for (var i = 0 ; i < items.length ; i++) {
        //         var item = items[i];
        //         if (item.kind == "file" && item.type) {
        //             var file = item.getAsFile();
        //             files.push(file)
        //         }
        //     }
        //     return {
        //         Success: true,
        //         DataType: "file",
        //         Data: files
        //     }
        // } else {
        //     return {
        //         Success: true,
        //         DataType: "text",
        //         Data: rows.join('\n')
        //     }
        // }
    },

}
