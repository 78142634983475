import { createI18n } from 'vue-i18n'
// import store from '@/store'

// User defined lang
import jaLocale from './ja'
import enLocale from './en'

// element-ui built-in lang
// import elementJaLocale from 'element-ui/lib/locale/lang/ja'
// import elementEnLocale from 'element-ui/lib/locale/lang/en'


const messages = {
  ja: {
    ...jaLocale,
    // ...elementJaLocale
  },
  en: {
    ...enLocale,
    // ...elementEnLocale
  },
}

// const userInfo = store.getters["auth/getUserInfo"]
const i18n = new createI18n({
    locale: /* (userInfo && userInfo.language) || */ navigator.language.split('-')[0],
    fallbackLocale: 'ja',
    globalInjection: true,
    messages,
})

export default i18n
