import http from '../mixins/api'

export default {
    namespaced: true,
    state: {
        To: "",
        CC: "",
        BCC: "",
        Subject: "",
        Content: "",
        DefaultText: "",
        TemplateText: "",
        getFooter: "",
        SentDate: null,
        MailList: [],
        WriterBrowserLanguage: "",
        FromCompanyName: "",
        GroupUsers: [],
        BCCGroupUserIds: [],
        CCGroupUserIds: [],
        BCCGroupEmails: [],
        CCGroupEmails: [],
        TotalRecords: 0,
        gridFilterData: { },
        gridSortData: { },
        gridPageData: { },
        replyToAddress: "",
        isReplyTo: false,
        IsBulkTo: false,
    },

    getters: {
        getTo(state) {
            return state.To
        },
        getCC(state) {
            return state.CC
        },
        getBCC(state) {
            return state.BCC
        },
        getSubject(state) {
            return state.Subject
        },
        getContent(state) {
            return state.Content
        },
        getDefaultText(state) {
            return state.DefaultText
        },
        getTemplateText(state) {
            return state.TemplateText
        },
        getFooter(state) {
            return state.Footer
        },
        getMailList(state) {
            state.MailList.forEach(d => {
                d.SentDate = new Date(d.SentDate)
            })

            return state.MailList
        },
        getGridDataTotalRecords(state) {
            return state.TotalRecords
        },
        getSentDate(state) {
            return state.SentDate
        },
        getWriterBrowserLanguage(state) {
            return state.WriterBrowserLanguage
        },
        getFromCompanyName(state) {
            return state.FromCompanyName
        },
        getGroupUsers(state) {
            return state.GroupUsers
        },
        getBCCGroupUserIds(state) {
            return state.BCCGroupUserIds
        },
        getCCGroupUserIds(state) {
            return state.CCGroupUserIds
        },
        getBCCGroupEmails(state) {
            return state.BCCGroupEmails
        },
        getCCGroupEmails(state) {
            return state.CCGroupEmails
        },
        getGridFilterData(state) {
            return state.gridFilterData
        },
        getGridSortData(state) {
            return state.gridSortData
        },
        getGridPageData(state) {
            return state.gridPageData
        },
        getReplyToAddress(state) {
            return state.replyToAddress
        },
        getIsReplyTo(state) {
            return state.isReplyTo
        },
        getIsBulkTo(state) {
            return state.IsBulkTo
        }
    },

    mutations: {
        setMailTemplate(state, MailTemplate) {
        },
        setFromCompanyName(state, FromCompanyName) {
            state.FromCompanyName = FromCompanyName
        },
        setTo(state, To) {
            state.To = To
        },
        setCC(state, CC) {
            state.CC = CC
        },
        setBCC(state, BCC) {
            state.BCC = BCC
        },
        setSubject(state, Subject) {
            state.Subject = Subject
        },
        setContent(state, Content) {
            state.Content = Content
        },
        setDefaultText(state, DefaultText) {
            state.DefaultText = DefaultText
        },
        setTemplateText(state, TemplateText) {
            state.TemplateText = TemplateText
        },
        setFooter(state, Footer) {
            state.Footer = Footer
        },
        setSentDate(state, SentDate) {
            state.SentDate = SentDate
        },
        setMailList(state, MailList) {
            state.MailList = MailList
        },
        setWriterBrowserLanguage(state, WriterBrowserLanguage) {
            state.WriterBrowserLanguage = WriterBrowserLanguage
        },
        setGroupUsers(state, GroupUsers) {
            state.GroupUsers = GroupUsers
        },
        setBCCGroupUserIds(state, BCCGroupUserIds) {
            state.BCCGroupUserIds = BCCGroupUserIds
        },
        setBCCGroupEmails(state, BCCGroupEmails) {
            state.BCCGroupEmails = BCCGroupEmails
        },
        setCCGroupUserIds(state, CCGroupUserIds) {
            state.CCGroupUserIds = CCGroupUserIds
        },
        setCCGroupEmails(state, CCGroupEmails) {
            state.CCGroupEmails = CCGroupEmails
        },
        setGridDataTotalRecords(state, TotalRecords) {
            state.TotalRecords = TotalRecords
        },
        setGridFilterData(state, params) {
            const gridFilterData = params.gridFilterData
            state.gridFilterData = gridFilterData
        },
        setGridSortData(state, params) {
            const gridSortOrder = params.gridSortOrder
            const gridSortField = params.gridSortField
            state.gridSortData = {sortField: gridSortField, sortOrder: gridSortOrder}
        },
        setGridPageData(state, params) {
            const gridPageData = params.gridPageData
            const Type = params.Type
            const Status = params.Status
            state.gridPageData = gridPageData
        },
        setReplyToAddress(state, replyToAddress) {
            state.replyToAddress = replyToAddress
        },
        setIsReplyTo(state, isReplyTo) {
            state.isReplyTo = isReplyTo
        },
        setIsBulkTo(state, IsBulkTo) {
            state.IsBulkTo = IsBulkTo
        },
    },

    actions: {
        async getMailTemplateByFormId({commit, state}, params) {
            const res = await http.post("/form/GetMailTemplateByFormId", {FormId: params.FormId, OPE: params.OPE})
            if(res.Success) {
                commit("setMailTemplate", res.MailTemplate)
                commit("setTo", res.To)
                commit("setBCC", res.BCC)
                commit("setSubject", res.Subject)
                commit("setDefaultText", res.DefaultText)
                commit("setTemplateText", res.TemplateText)
                commit("setSentDate", null)
                commit("setFooter", res.Footer)
                commit("setWriterBrowserLanguage", res.WriterBrowserLanguage)
                commit("setFromCompanyName", res.FromCompanyName)
                commit("setGroupUsers", res.groupUsers)
                commit("setCCGroupUserIds", [])
                commit("setBCCGroupUserIds", [])
                commit("setIsBulkTo", false)

                const userInfo = JSON.parse(localStorage.userInfo)
                commit("setIsReplyTo", userInfo.isReplyTo ? userInfo.isReplyTo : false)
                
            }

            return res;
        },

        async getBulkMailTemplate({commit, state}, params) {
            const res = await http.post("/form/GetBulkMailTemplate", {OPE: params.OPE})
            if(res.Success) {
                commit("setMailTemplate", res.MailTemplate)
                // commit("setTo", res.To)
                commit("setBCC", res.BCC)
                commit("setSubject", res.Subject)
                commit("setDefaultText", res.DefaultText)
                commit("setTemplateText", res.TemplateText)
                commit("setSentDate", null)
                commit("setFooter", res.Footer)
                commit("setFromCompanyName", res.FromCompanyName)
                commit("setCCGroupEmails", "")
                commit("setBCCGroupEmails", "")
                commit("setIsBulkTo", true)
                
                const userInfo = JSON.parse(localStorage.userInfo)
                commit("setIsReplyTo", userInfo.isReplyTo ? userInfo.isReplyTo : false)
            }

            return res;
        },

        async getMailList({commit, getters, state}) {
            const page = getters.getGridPageData
            const sort = getters.getGridSortData
            const filter = getters.getGridFilterData
            const res = await http.post("/form/GetSentMailList", {Page: page, Sort: sort, Filter: filter})
            if(res.Success) {
                commit("setMailList", res.MailList)
                commit("setGridDataTotalRecords", res.TotalRecords)
            }

            return res;
        },

        async setMailTemplateTextByFormId({commit, state}, params) {
            const res = await http.post("/form/SetMailTemplateTextByFormId", {
                FormId: params.FormId,
                OPE: params.OPE,
                Subject: state.Subject,
                DefaultText: state.DefaultText,
                TemplateText: state.TemplateText,
            })

            return res;

        },

        async getMailDataById({commit}, _id) {
            const res = await http.post("/form/GetMailDataById", {_id: _id})
            if(res.Success) {
                commit("setTo", res.sentEmail.ToEmail)
                commit("setFromCompanyName", res.sentEmail.FromCompanyName)
                commit("setBCC", res.sentEmail.BCCText)
                commit("setCC", res.sentEmail.CCText)
                commit("setSubject", res.sentEmail.Subject)
                commit("setContent", res.sentEmail.HTML)
                commit("setSentDate", res.sentEmail.SentDate)
                commit("setReplyToAddress", res.sentEmail.ReplyToAddress)
                
            }

            return res;
        },

        async sendBulkMail({}, params) {
            const subjectText = params.subjectText
            const defaultText = params.defaultText
            const CCGroupEmails = params.CCGroupEmails
            const BCCGroupEmails = params.BCCGroupEmails
            const FromCompanyName = params.FromCompanyName
            const ReplyToAddress = params.ReplyToAddress
            const BulkCheckFormList = params.BulkCheckFormList
            const isSelectedAll = params.isSelectedAll
            const Status = params.Status
            const res = await http.post("/form/SendBulkFormStatusMail", {OPE: params.OPE, SubjectText: subjectText, DefaultText: defaultText, CCGroupEmails: CCGroupEmails, BCCGroupEmails: BCCGroupEmails, FromCompanyName: FromCompanyName, ReplyToAddress: ReplyToAddress, selectedFormId: BulkCheckFormList, isSelectedAll: isSelectedAll, formStatus: Status })

            return res
        },
    },
}

