import http from '../mixins/api'

export default {
    namespaced: true,
    state: {
        routeList: [],
        expandedIndexList: [],
        activeRouteCode: null,
        loaded: false,
        waiting: false,
        routeDepartmentId: "",
    },

    getters: {
        getRouteList(state) {
            return state.routeList;
        },
        getExpandedIndexList(state) {
            return state.expandedIndexList;
        },
        getActiveRouteCode(state) {
            return state.activeRouteCode;
        },
        getWaiting(state) {
            return state.waiting
        },
        getRouteDepartmentId(state) {
            return state.routeDepartmentId
        }
    },

    mutations: {
        changeExpandedIndexList(state, expandedIndex) {
            const index = state.expandedIndexList.findIndex(x => x == expandedIndex);
            if(index != -1) {
                const newExpandedIndexList = state.expandedIndexList.filter(x => x != expandedIndex)
                state.expandedIndexList.splice(0, state.expandedIndexList.length, ...newExpandedIndexList)
            } else {
                state.expandedIndexList.push(expandedIndex)
            }

        },
        closeAllRoute(state) {
            state.expandedIndexList = []
        },
        expandAllRoute(state) {
            const routeList = state.routeList
            for (let i = 0; i < routeList.length; i++) {
                const route = routeList[i]
                if(route.items)
                    state.expandedIndexList.push(route.index)
            }
        },
        setActiveRouteCode(state, activeCode) {
            state.activeRouteCode = activeCode
        },
        setRouteList(state, routeList) {
            state.routeList = []
            state.routeList.splice(0, state.routeList.length, ...routeList)
        },
        setLoaded(state, loaded) {
            state.loaded = loaded
        },
        setWaiting(state, waiting) {
            state.waiting = waiting
        },
        setRouteDepartmentId(state, routeDepartmentId) {
            state.routeDepartmentId = routeDepartmentId
        }
    },

    actions: {
        // async getRouteList({commit, state}, params=[{}, {}, true, true]) {
        //     if(!state.loaded) {
        //         const res = await http.post("/user/GetUserRouteList", params[0], params[1], params[2], params[3])
        //         if(res.Success) {
        //             commit("setRouteList", res.RouteList)
        //             state.loaded = true
        //         }
        //     }
        // },

        async getRouteList({commit, state}, params={}) {
            if(!state.loaded) {
                const DepartmentId = params.DepartmentId ? params.DepartmentId : state.routeDepartmentId
                const res = await http.post("/user/GetUserRouteList", {DepartmentId: DepartmentId})
                if(res.Success) {
                    commit("setRouteList", res.RouteList)
                    commit("setRouteDepartmentId", params.DepartmentId)
                    commit("setLoaded", true)
                    commit('expandAllRoute')
                }

                return res
            }

            return {}
        },

        setActiveRouteName({commit, state}, activeName) {
            const routeList = state.routeList
            if(routeList) {
                let result = null
                for(let i = 0; i < routeList.length; i++) {
                    const route = routeList[i]
                    if(route.name == activeName) {
                        result = route
                        break
                    }
                    if(route.items) {
                        for(let j = 0; j < route.items.length; j++) {
                            if(route.items[j].name == activeName) {
                                result = route
                                break
                            }
                        }
                    }
                }
                if(result)
                    commit("setActiveRouteCode", result.index)
            }
        },

        async countForms({commit, state}, params) {
            const DepartmentId = params.DepartmentId ? params.DepartmentId : state.routeDepartmentId
            const res = await http.post("/form/GetCountForms", {DepartmentId: DepartmentId})
        },

        async isValidMobileRoute({commit, state}) {
            const activeRouteCode = state.activeRouteCode
            const routeList = state.routeList
            if(routeList) {
                let result = null
                for(let i = 0; i < routeList.length; i++) {
                    const route = routeList[i]
                    if(route.index == activeRouteCode) {
                        result = route
                        break
                    }
                    if(route.items) {
                        for(let j = 0; j < route.items.length; j++) {
                            if(route.items[j].index == activeRouteCode) {
                                result = route
                                break
                            }
                        }
                    }
                }
                console.log(result)

                if (result) {
                    if (result.name == "changeRegistrationInfo") {
                        return true
                    }
                    return result.mobileAble
                }
            }
            return true
        }
    },
}

