import http from '../mixins/api'
import { uuid } from "vue-uuid";
import filters from '@/mixins/filters'
import i18n from '@/lang'
import * as moment from 'moment-timezone';
import store from '@/store';
import constants from '@/mixins/constants';


export default {
    namespaced: true,
    state: {
        isPreview: false,
        invalidViewMode: false,
        componentsList: [], // all
        components: [],     // editing
        templateList: [
            // {
            //     userAttr: "kojin",
            //     appTypeList: [],
            //     components: [],
            // },
            // {
            //     userAttr: "houjin",
            //     appTypeList: [],
            //     components: [],
            // },
        ],
        template: {
            // DocumentName: "",
            // Department: "",
            // userAttr: null,
            // userAttrList: [],
            // appTypeList: [],
            // openDate: null,
            // endDate: null,
            // components: []
        },
        AllAppTypeList: [
        ],
        activeAppTypeList: [],
        info: {
            DocumentName: "",
            DepartmentName: "",
            DepartmentId: "",
            TemplateNote: "",
            openDate: null,
            endDate: null,
            CreatedUserEmail: "",
            UpdatedDate: null,
            ConfirmDate: null,
            GarbageDate: null,
            CreatedDate: null,
            _id: null,
            VersionText: null,
            TemplateNoteVersionText: null,
            ScopeOfPublication: "",
            ControlText: "",
            CreatedUserId: "",
            DetailFormStatusList: [],
        },
        activeUserAttrValue: null,
        Status: null,
        edited: false,
        Stamped: false,
        CanStamp: false,
    },
    getters: {
        getCreatedDate(state) {
            return state.info.CreatedDate
        },
        getGarbageDate(state) {
            return state.info.GarbageDate
        },
        getConfirmDate(state) {
            return state.info.ConfirmDate
        },
        getCreatedUserEmail(state) {
            return state.info.CreatedUserEmail
        },
        getUpdatedDate(state) {
            return state.info.UpdatedDate
        },
        getIsPreview(state) {
            return state.isPreview
        },
        getIsInvalidViewMode(state) {
            return state.invalidViewMode
        },
        getStatus(state) {
            return state.Status
        },
        getDocumentName(state) {
            return state.info.DocumentName
        },
        getScopeOfPublication(state) {
            return state.info.ScopeOfPublication
        },
        getScopeOfPublicationList(state) {
            return state.ScopeOfPublicationList
        },
        getDepartmentName(state) {
            return state.info.DepartmentName
        },
        getCompanyName(state) {
            return state.info.CompanyName
        },
        getUserAttr(state) {
            return state.activeUserAttrValue   
            return state.UserAttr
        },
        getUserAttrList(state) {
            return state.UserAttrList || []
        },
        getAppType(state) {
            const AppType = state.AllAppTypeList.filter(x => x.UserAttrValue == state.activeUserAttrValue)
            if(AppType) {
                return AppType
            }
            return []
            // return []
            // return state.AllAppTypeList.find(x => x.UserAttrValue == state.activeUserAttrValue).AppTypeList
            // return state.activeAppTypeList;
            // return state.AppType || []
        },
        getOpenBegin(state) {
            return state.info.OpenBegin
        },
        getOpenEnd(state) {
            return state.info.OpenEnd
        },
        getSortNumberByTemplate(state) {
            return state.info.SortNumberByTemplate
        },
        getDefaultDetailFormStatusId(state) {
            return state.info.DefaultDetailFormStatusId
        },
        getControlText(state) {
            return state.info.ControlText
        },
        getComponents(state) {
            return state.components
        },
        getComponentsData(state) {

        },
        getTemplateData(state) {
            return state.template
        },
        getNextComponentNo(state) {
            if(state.components) {
                return state.components.length + 1
            }
            return 1;
        },
        getTemplateId(state) {
            return state.info._id
        },
        getEdited(state) {
            return state.edited
        },
        getDepartmentInfo(state) {
            return state.info.DepartmentId
        },
        getTemplateNote(state) {
            return state.info.TemplateNote
        },
        getCreatedUserId(state) {
            return state.info.CreatedUserId
        },
        getStamped(state) {
            return state.Stamped
        },
        getCanStamp(state) {
            return state.CanStamp
        },
        isInitTemplate(state) {
            const componentsList = state.componentsList
            let k = componentsList.filter(c => c.UserAttrValue == "kojin")
            let h = componentsList.filter(c => c.UserAttrValue == "houjin")
            if(k.length == 1) {
                k = k[0]
                if(k.ComponentType == "Editor") {
                    return true;
                }
            }
            if(h.length == 1) {
                h = h[0]
                if(h.ComponentType == "Editor") {
                    return true;
                }
            }
            return false;
        },
        getDetailFormStatusList(state) {
            return state.info.DetailFormStatusList
        },

    },
    mutations: {
        setVersionText(state, VersionText) {
            state.info.VersionText = VersionText
        },
        setTemplateNoteVersionText(state, TemplateNoteVersionText) {
            state.info.TemplateNoteVersionText = TemplateNoteVersionText
        },
        setCreatedDate(state, CreatedDate) {
            state.info.CreatedDate = CreatedDate
        },
        setGarbageDate(state, GarbageDate) {
            state.info.GarbageDate = GarbageDate
        },
        setConfirmDate(state, ConfirmDate) {
            state.info.ConfirmDate = ConfirmDate
        },
        setCreatedUserEmail(state, CreatedUserEmail) {
            state.info.CreatedUserEmail = CreatedUserEmail
        },
        setUpdatedDate(state, UpdatedDate) {
            state.info.UpdatedDate = UpdatedDate
        },
        setIsPreview(state, isPreview) {
            state.isPreview = isPreview
        },
        setDepartmentId(state, _id) {
            state.edited = true
            state.info.DepartmentId = _id
        },
        setTemplateId(state, _id) {
            state.info._id = _id
        },
        setStatus(state, Status) {
            state.Status = Status
        },
        setDocumentName(state, DocumentName) {
            state.edited = true
            state.info.DocumentName = DocumentName
        },
        setScopeOfPublication(state, ScopeOfPublication) {
            state.edited = true
            state.info.ScopeOfPublication = ScopeOfPublication
        },
        setScopeOfPublicationList(state, ScopeOfPublicationList) {
            state.ScopeOfPublicationList = ScopeOfPublicationList
        },
        setDepartmentName(state, DepartmentName) {
            state.edited = true
            state.info.DepartmentName = DepartmentName
        },
        setCompanyName(state, CompanyName) {
            state.edited = true
            state.info.CompanyName = CompanyName
        },
        setUserAttr(state, userAttr) {
            const componentsList = state.componentsList
            const oldFormList = state.componentsList.filter(c => c.UserAttrValue == state.activeUserAttrValue)
            var tmp = [...componentsList, ...oldFormList]
                .reduce((obj, it) => {
                    obj[it.Id] = it;
                    return obj;
                }, {});
            const result = Object.values(tmp);
            state.componentsList.splice(0, state.componentsList.length, ...result)
            const activeFormList = state.componentsList.filter(c => c.UserAttrValue == userAttr).sort(function(a, b) {
                    if(a.No < b.No) return -1;
                    if(a.No > b.No) return 1;
                    return 0;
            })
            state.components.splice(0, state.components.length, ...activeFormList)
            state.activeUserAttrValue = userAttr

            // let oldIndex = state.componentsList.findIndex(t => t.UserAttr == state.components.UserAttr)
            // let oldTemplate = state.components
            // console.log(oldIndex)
            // if(oldIndex == -1) {
            //     state.componentsList.push(oldTemplate)
            // } else {
            //     state.componentsList.splice(oldIndex, 1, oldTemplate)
            // }
            // let newTemplate = state.componentsList.find(t => t.UserAttr == userAttr)
            // if(!newTemplate) {
            //     newTemplate = {
            //         UserAttr: userAttr,
            //         appTypeList: [],
            //         components: [],
            //     }
            // }
            // let newIndex = state.componentsList.findIndex(t => t.UserAttr == userAttr)
            // newTemplate.UserAttr = userAttr
            // if(newIndex != -1) {
            //     state.componentsList.splice(newIndex, 1, newTemplate)
            // } else {
            //     state.componentsList.push(newTemplate)
            // }
            // console.log(state, userAttr)
            // state.components = newTemplate
        },
        setAppType(state, value) {
            const AppTypeValue = value.AppTypeValue
            const IsActive = value.IsActive
            state.edited = true
            const AppTypeList = state.AllAppTypeList.filter(x => x.AppTypeValue == AppTypeValue)
            for (let i = 0; i < AppTypeList.length; i++) {
                AppTypeList[i].IsActive = IsActive
            }
            const ActiveAppTypeList = state.AllAppTypeList.filter(x => x.UserAttrValue == state.activeUserAttrValue)
            state.activeAppTypeList = ActiveAppTypeList
        },

        setAppTypeLabelAndValue(state, value) {
            const AppTypeValue = value.AppTypeValue
            const AppTypeLabel = value.AppTypeLabel
            const AppTypeList = state.AllAppTypeList.filter(x => x.AppTypeValue == AppTypeValue)
            for (let i = 0; i < AppTypeList.length; i++) {
                AppTypeList[i].AppTypeLabel = AppTypeLabel
            }
            const ActiveAppTypeList = state.AllAppTypeList.filter(x => x.UserAttrValue == state.activeUserAttrValue)
            state.activeAppTypeList = ActiveAppTypeList
            state.edited = true
        },

        resetAppTypeEditActive(state) {
            state.AllAppTypeList.map(x => x.IsEditActive = false)
        },

        setAllAppType(state, allAppTypeList) {
            state.AllAppTypeList = allAppTypeList
            state.activeAppTypeList = allAppTypeList.filter(x => x.UserAttrValue == state.activeUserAttrValue)
        },
        setStamped(state, Stamped) {
            state.Stamped = Stamped;
        },
        setCanStamp(state, CanStamp) {
            state.CanStamp = CanStamp;
        },
        setOpenBegin(state, OpenBegin) {
            state.edited = true
            // state.info.OpenBegin = filters.formatDate(OpenBegin, null, "HH:mm:ss")
            state.info.OpenBegin = OpenBegin
            
            // state.info.OpenBegin = filters.formatDate(OpenBegin, null, "HH:mm:ss")

            // const userInfo = store.getters["auth/getUserInfo"]
            // const dateFormat = userInfo ? userInfo.dateformat : "YYYY/MM/DD"
            // const timeZone = userInfo ? userInfo.timezone : "Asia/Tokyo"
            // console.log(timeZone)
            // state.info.OpenBegin = moment(openBegin).tz(timeZone).toDate()
            // console.log(state.info.OpenBegin)

        },
        setOpenEnd(state, OpenEnd) {
            state.edited = true
            // state.info.OpenEnd = filters.formatDate(OpenEnd, null, "HH:mm:ss")
            state.info.OpenEnd = OpenEnd
        },
        setSortNumberByTemplate(state, SortNumberByTemplate) {
            state.edited = true
            state.info.SortNumberByTemplate = SortNumberByTemplate;
        },
        setDefaultDetailFormStatusId(state, DefaultDetailFormStatusId) {
            state.edited = true
            state.info.DefaultDetailFormStatusId = DefaultDetailFormStatusId;
        },
        setControlText(state, ControlText) {
            state.edited = true
            state.info.ControlText = ControlText;
        },
        setTemplate(state, template) {
            state.templateList = []
            state.templateList.push(...template.components)
            // state.template = template.components[0]
            state.componentsList = []
            state.componentsList.push(...template.components)
        },
        setUserAttrList(state, userList) {
            state.template.userAttrList = userList
        },
        setInitComponents(state, components) {
            // state.template.components = components
            state.componentsList.splice(0, state.componentsList.length, ...components)
        },
        setComponents(state, components) {
            state.edited = true
            state.components = []
            state.components.splice(0, state.components.length, ...components) 
        },
        setComponentsByItemId(state, Data){
        },
        setComponentByItemId(state, Data){
            if(!state.components) {
                state.components = []
            }
            const component = state.components.find(x => x.Id == Data.Id) 
            if(component) {
                const field = component.items.find(x => x.ItemId == Data.ItemId) 
                if(field) {
                    Object.assign(field, Data)
                    // field.label = Data.label
                    // field.value = Data.value
                    // field.xtype = Data.xtype
                } else {
                    component.items.push(Data)
                }
            } else {
                state.components.push({Id: Data.Id, items: [Data]})
            }
            state.edited = true
        },
        setComponentPropsByItemId(state, Data){
            if(!state.components) {
                state.components = []
            }
            const component = state.components.find(x => x.Id == Data.Id) 
            if(component) {
                component[Data.label] = Data.value
            } else {
                state.components.push({
                    Id: Data.Id,
                    [Data.label]: Data.value,
                    items:[]
                })
            }
            state.edited = true
        },
        upComponent(state, index) {
            let No = index - 1
            if(No > 0) {
                let tmp = state.components[No - 1]
                state.components[No - 1] = state.components[No]
                state.components[No] = tmp
                for(let i = 0; i < state.components.length; i++) {
                    state.components[i].No = i + 1
                }
                state.edited = true
            }
        },
        downComponent(state, index) {
            let No = index - 1
            if(No < state.components.length - 1) {
                let tmp = state.components[No + 1]
                state.components[No + 1] = state.components[No]
                state.components[No] = tmp
                for(let i = 0; i < state.components.length; i++) {
                    state.components[i].No = i + 1
                }
                state.edited = true
            }
        },
        addComponent(state, com) {
            com.items = com.items.map(item =>{
                item.ItemId = uuid.v4();
                return item;
            })
            let inserted = false
            for(let i = 0; i < state.components.length; i++) {
                if(inserted) {
                    state.components[i].No = state.components[i].No + 1
                }
                else if(state.components[i].No == com.No) {
                    state.components.splice(i, 0, com)
                    inserted = true
                }
            }
            state.componentsList.push(com)
            if(!inserted) {
                state.components.push(com)
            }
            state.edited = true
        },

        removeComponentById(state, Id) {
            let index = state.componentsList.findIndex(x => x.Id == Id)
            if(index != -1) {
                state.componentsList.splice(index, 1)
            }
            index = state.components.findIndex(x => x.Id == Id)
            if(index != -1) {
                state.components.splice(index, 1)
            }
            state.components.sort(function(a,b){
                if(a.No < b.No) return -1;
                if(a.No > b.No) return 1;
                return 0;
            })
            for(let i = 0; i < state.components.length; i++) {
                state.components[i].No = i + 1
            }
            state.edited = true
        },


        setEdited(state, edit) {
            state.edited = edit
        },
        clear(state) {
            state.componentsList = []
            state.components = []   
            state.templateList = []
            state.template = {}
            state.AllAppTypeList = [
                {
                    UserAttrValue: "kojin",
                    AppTypeList: []
                },
                {
                    UserAttrValue: "houjin",
                    AppTypeList: []
                }
            ]
            state.activeAppTypeList = []
            state.info = {
                DocumentName: "",
                DepartmentName: "",
                DepartmentId: "",
                openDate: null,
                endDate: null,
                ScopeOfPublication: "",
                _id: null,
            }
            state.activeUserAttrValue = null
            state.Status = null
            state.edited = false
        },
        setTemplateNote(state, text) {
            state.info.TemplateNote = text
        },
        setCreatedUserId(state, CreatedUserId) {
            state.info.CreatedUserId = CreatedUserId
        },
        copyFormTemplateByInactiveUserattrs(state) {
            let originComponentsList = state.componentsList.filter(c => c.UserAttrValue != state.activeUserAttrValue)
            const nextAppType = state.AllAppTypeList.find(x => x.UserAttrValue == state.activeUserAttrValue)
            let newComponentsList = JSON.parse(JSON.stringify(originComponentsList)); 
            for(let i = 0; i < newComponentsList.length; i++) {
                delete newComponentsList[i]._id
                newComponentsList[i].UserAttrValue = nextAppType.UserAttrValue
                newComponentsList[i].UserAttr = nextAppType.UserAttrValue
                newComponentsList[i].Id = uuid.v4()
                if(newComponentsList[i].items) {
                    for(let j = 0; j < newComponentsList[i].items.length; j++) {
                        delete newComponentsList[i].items[j]._id
                        delete newComponentsList[i].items[j].ComponentId
                        newComponentsList[i].items[j].Id = newComponentsList[i].Id
                        newComponentsList[i].items[j].ItemId = uuid.v4();
                    }
                }
            }
            newComponentsList.sort(function(a, b) {
                    if(a.No < b.No) return -1;
                    if(a.No > b.No) return 1;
                    return 0;
            })
            const newList = [...newComponentsList, ...originComponentsList]
            state.componentsList.splice(0, state.componentsList.length, ...newList)
            state.components.splice(0, state.components.length, ...newComponentsList)
            state.edited = true
        },
        invalidViewMode(state, mode) {
            state.invalidViewMode = mode
        },
        setDetailFormStatusList(state, detailFormStatusList) {
            state.info.DetailFormStatusList = detailFormStatusList ? detailFormStatusList : []
        },

    },
    actions: {
        async getTemplateById(context, params) {
            const id = params.id 
            const departmentId = params.departmentId
            const res = await http.post("/form/GetTemplateFormById", {"templateId": id, "departmentId": departmentId})

            return res
        },
        async setTemplateById (context, params) {
            const id = params.id 
            const departmentId = params.departmentId
            const res = await context.dispatch("getTemplateById", {id: id, departmentId: departmentId})
            if(res.Success) {
                const template = res.Data
                // context.commit('setTemplate', res.ComponentsList)
                context.commit('setInitComponents', res.ComponentsList)
                context.commit('setTemplateId', id);
                context.commit('setStatus', template.Status);
                context.commit('setDocumentName', template.DocumentName);
                context.commit('setScopeOfPublication', template.ScopeOfPublication);
                context.commit('setScopeOfPublicationList', res.ScopeOfPublicationList);
                context.commit('setDepartmentName', template.DepartmentName);
                context.commit('setDepartmentId', template.DepartmentId);
                context.commit('setCompanyName', template.CompanyName);
                context.commit('setTemplateNote', template.TemplateNote);
                context.commit('setCreatedUserId', template.CreatedUserId);
                context.commit('setCreatedUserEmail', template.CreatedUserEmail);
                
                if(template.OpenBegin) {
                    context.commit('setOpenBegin', new Date(template.OpenBegin));
                } else {
                    context.commit('setOpenBegin', null);
                }
                if(template.OpenEnd) {
                    context.commit('setOpenEnd', new Date(template.OpenEnd));
                } else {
                    context.commit('setOpenEnd', null);
                }
                if(!template.ScopeOfPublication || template.ScopeOfPublication == "kojin_houjin") {
                    context.commit('setUserAttr', "kojin");
                } else if(template.ScopeOfPublication == "kojin") {
                    context.commit('setUserAttr', "kojin");
                } else {
                    context.commit('setUserAttr', "houjin");
                }
                context.commit('setAllAppType', res.AppTypeList);
                context.commit('setStamped', res.Stamped);
                context.commit('setCanStamp', res.CanStamp);
                context.commit('invalidViewMode', false);
                context.commit('setIsPreview', template.Status !== 0);
                context.commit('setConfirmDate', new Date(template.ConfirmDate));
                context.commit('setGarbageDate', new Date(template.GarbageDate));
                context.commit('setUpdatedDate', new Date(template.UpdatedDate));
                context.commit('setCreatedDate', new Date(template.CreatedDate));
                context.commit('setVersionText', template.VersionText);
                context.commit('setTemplateNoteVersionText', template.TemplateNoteVersionText);
                context.commit('setSortNumberByTemplate', template.SortNumberByTemplate);
                context.commit('setDefaultDetailFormStatusId', template.DefaultDetailFormStatusId);
                context.commit("setDetailFormStatusList", res.DetailFormStatusList)
                context.commit('setControlText', template.ControlText);
                
                
                store.commit("planedStore/setDepartmentId", template.DepartmentId)
                store.dispatch("planedStore/getValidateList")


            }
            context.state.edited = false;

            return res
        },

        async checkUpdateTemplateForm({context, state}) {
            const info = state.info
            const VersionText = info.VersionText
            const res = await http.post("/form/CheckUpdateTemplateForm", {_id: info._id, VersionText: VersionText})
            
            return res;
        },

        async updateTemplateForm({context, state}, params) {
            const template = state.template
            const templateList = state.templateList
            const componentsList = state.componentsList
            const info = state.info
            const AppTypeList = state.AllAppTypeList
            const res = await http.post("/form/UpdateTemplateForm", {componentsList: componentsList, info: info, AppTypeList: AppTypeList, ...params})
            if(res.Success) {
                state.edited = false;
                state.info.VersionText = res.VersionText
            }
            store.commit("planedStore/setDepartmentId", template.DepartmentId)
            store.dispatch("planedStore/getValidateList")
            return res
        },


        async confirmTemplateForm({dispatch, state}) {
            const res_update = await dispatch("updateTemplateForm", {checkInitTemplate: true})
            if(res_update.Success) {
                const templateId = state.info._id
                const res = await http.post("/form/ConfirmTemplateForm", {templateId: templateId})

                return res
            } else {
                return res_update
            }
        },

        async garbageTemplateForm({dispatch, state}) {
            const Status = state.Status
            if(Status == 0) {
                await dispatch("updateTemplateForm")
            }
            const templateId = state.info._id
            const res = await http.post("/form/GarbageTemplateForm", {templateId: templateId})

            return res
        },

        async copyTemplateForm({dispatch, state}, params) {
            const Status = state.Status
            const activeDepartmentId = params.activeDepartmentId
            if(Status == 0) {
                await dispatch("updateTemplateForm")
            }
            const templateId = state.info._id
            const res = await http.post("/form/CopyTemplateForm", {templateId: templateId, activeDepartmentId: activeDepartmentId})

            return res
        },

        async updateTemplateNote({commit, state}, value) {
            const templateId = state.info._id
            const res = await http.post("/form/UpdateTemplateNote", {"templateId": templateId, "TemplateNote": value})
            if(res.Success) {
                commit("setTemplateNote", value)
                commit('setTemplateNoteVersionText', res.TemplateNoteVersionText);
            }
        },


        async checkUpdateTemplateNoteVersion({context, state}) {
            const info = state.info
            const TemplateNoteVersionText = info.TemplateNoteVersionText
            const res = await http.post("/form/CheckUpdateTemplateNoteVersion", {_id: info._id, TemplateNoteVersionText: TemplateNoteVersionText})
            
            return res;
        },

        
        async stampFormTemplate({commit, state}) {
            const templateId = state.info._id
            const res = await http.post("/form/StampFormByUserName", {_id: templateId, Type: "Template"})
            if(res.Success) {
                commit('setStamped', true);
            }
            return res;
        },

        async removeStampFormTemplate({commit, state}) {
            const templateId = state.info._id
            const res = await http.post("/form/RemoveStampForm", {_id: templateId, Type: "Template"})
            if(res.Success) {
                commit('setStamped', false);
            }
            return res;
        },

        async updateTemplateFormInfo({commit, state}, data) {
            const key = data.key
            const value = data.value
            const id = data.id
            if(data.Status == constants.Status.Public) {

                const res = await http.post('/form/updateTemplateFormInfo', {
                    TemplateId: id,
                    Info: {
                        [key]: value
                    }
                })

                return res;
            }
            else if(data.Status == constants.Status.Garbage && key == "SortNumberByTemplate") {
                const res = await http.post('/form/updateTemplateFormInfo', {
                    TemplateId: id,
                    Info: {
                        [key]: value
                    }
                })

                return res;
            }

            return {};
        }


        // async inviteUserInTemplateForm({context, state}) {
        //     const templateId = state.info._id
        //     const res = await http.post("/form/ConfirmTemplateForm", {templateId: templateId})
        //
        // }
    }
}

