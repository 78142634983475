import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import i18n from '@/lang'
import helper from '@/mixins/helper'
import constants from '@/mixins/constants'
import socketHelper from "@/mixins/socketHelper"
import isMobile from '@/mixins/device'

const routes = [
    {
        path: '/',
        redirect: "/admin_login",
    },
    {
        path: '/admin_login',
        component: () => import('../views/AdminLogin.vue'),
        name: "admin_login",
    },
    
    {
        path: '/admin_auth_login_door',
        component: () => import('../views/AdminAuthLoginDoor.vue'),
        name: "admin_auth_login_door",
    },
    {
        path: '/admin_start',
        component: () => import('../views/AdminStart.vue'),
        name: "admin_start",
    },
    {
        path: '/admin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
        meta: { requiresAuth: true },
        redirect: "/admin/receivedBox/@",
        children: [
            {
                path: "top",
                name: "top",
                redirect: "/admin/top/@",
                component: () => import('../views/Top.vue'),
                meta: { requiresAuth: true, name: "top"},
                // name: "top"
            },
            {
                path: "top/:departmentId",
                name: "top2",
                component: () => import('../views/Top.vue'),
                meta: { requiresAuth: true , name: "top"},
                // name: "top"
            },
            {
                path: "draft/:departmentId",
                name: "draft",
                // name: "draft",
                component: () => import('../views/Draft.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1}, queryTemplateFormView: true, name: "draft" },
            },
            {
                path: "draft/:departmentId/:templateFormId",
                name: "draftInfo",
                // name: "draft",
                component: () => import('../views/Draft.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1}, loadTemplateFormView: true , name: "draft" },
            },
            {
                path: "publicFormList/:departmentId",
                name: "publicFormList",
                // name: "acceptFormList",
                component: () => import('../views/PublicFormList.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1}, queryTemplateFormView: true, name: "publicFormList"},
            },
            {
                path: "publicFormList/:departmentId/:templateFormId",
                name: "publicFormListInfo",
                // name: "acceptFormList",
                component: () => import('../views/PublicFormList.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1}, loadTemplateFormView: true , name: "publicFormList" },
            },
            {
                path: "receivedBox/:departmentId",
                name: "receivedBoxNormal",
                // name: "receivedBox",
                component: () => import('../views/ReceivedBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, redirectName: "receivedBox", isFormView: true, viewDataTable: true, name: "receivedBox" },
            },
            {
                path: "receivedBox/:departmentId/:detailFormStatusId",
                name: "receivedBox",
                // name: "receivedBox",
                component: () => import('../views/ReceivedBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true, viewDataTable: true, name: "receivedBox" },
            },
            {
                path: "receivedBox/:departmentId/:detailFormStatusId/:formId",
                name: "receivedBoxFormId",
                // name: "receivedBox",
                component: () => import('../views/ReceivedBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true,  viewDataTable: false, loadFormView: true,  name: "receivedBox"  },
            },
            {
                path: "acceptBox/:departmentId",
                name: "acceptBoxNormal",
                // name: "receivedBox",
                component: () => import('../views/AcceptFormBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, redirectName: "acceptBox", isFormView: true, viewDataTable: true, name: "acceptBox"  },
            },
            {
                path: "acceptBox/:departmentId/:detailFormStatusId",
                name: "acceptBox",
                // name: "receivedBox",
                component: () => import('../views/AcceptFormBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true, viewDataTable: true, name: "acceptBox"  },
            },
            {
                path: "acceptBox/:departmentId/:detailFormStatusId/:formId",
                name: "acceptBoxFormId",
                // name: "receivedBox",
                component: () => import('../views/AcceptFormBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true,  viewDataTable: false , loadFormView: true, name: "acceptBox" },
            },
            {
                path: "rejectedBox/:departmentId",
                name: "rejectedBoxNormal",
                // name: "receivedBox",
                component: () => import('../views/RejectedFormBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, redirectName: "rejectedBox", isFormView: true, viewDataTable: true,  name: "rejectedBox" },
            },
            {
                path: "rejectedBox/:departmentId/:detailFormStatusId",
                name: "rejectedBox",
                // name: "receivedBox",
                component: () => import('../views/RejectedFormBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true, viewDataTable: true,  name: "rejectedBox" },
            },
            {
                path: "rejectedBox/:departmentId/:detailFormStatusId/:formId",
                name: "rejectedBoxFormId",
                // name: "receivedBox",
                component: () => import('../views/RejectedFormBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true,  viewDataTable: false, loadFormView: true ,name: "rejectedBox"  },
            },
            {
                path: "notReceivedBox/:departmentId",
                name: "notReceivedBoxNormal",
                // name: "notReceivedBox",
                component: () => import('../views/NotReceivedBox.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, redirectName: "notReceivedBox", isFormView: true, viewDataTable: true, name: "notReceivedBox" },
            },
            {
                path: "notReceivedBox/:departmentId/:detailFormStatusId",
                name: "notReceivedBox",
                // name: "notReceivedBox",
                component: () => import('../views/NotReceivedBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true, viewDataTable: true, name: "notReceivedBox" },
            },
            {
                path: "notReceivedBox/:departmentId/:detailFormStatusId/:formId",
                name: "notReceivedBoxFormId",
                // name: "notReceivedBox",
                component: () => import('../views/NotReceivedBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true,  viewDataTable: false , loadFormView: true , name: "notReceivedBox" },
            },
            {
                path: "storageBox/:departmentId",
                name: "storageBoxNormal",
                component: () => import('../views/StorageBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, redirectName: "storageBox", isFormView: true, viewDataTable: true, name: "storageBox" },
            },
            {
                path: "storageBox/:departmentId/:detailFormStatusId",
                name: "storageBox",
                component: () => import('../views/StorageBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true, viewDataTable: true, name: "storageBox" },
            },
            {
                path: "storageBox/:departmentId/:detailFormStatusId/:formId",
                name: "storageBoxFormId",
                component: () => import('../views/StorageBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true,  viewDataTable: false , loadFormView: true , name: "storageBox" },
            },
            {
                path: "garbage/:departmentId",
                name: "garbage",
                component: () => import('../views/GarbageBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1}, queryTemplateFormView: true,  name: "garbage"},
            },
            {
                path: "garbage/:departmentId/:templateFormId",
                name: "garbageInfo",
                component: () => import('../views/GarbageBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1}, loadTemplateFormView: true  , name: "garbage"},
            },
            {
                path: "deleted/:departmentId",
                name: "deletedNormal",
                component: () => import('../views/DeletedBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, redirectName: "deleted", isFormView: true, viewDataTable: true, name: "deleted" },
            },
            {
                path: "deleted/:departmentId/:detailFormStatusId",
                name: "deleted",
                component: () => import('../views/DeletedBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true, viewDataTable: true, name: "deleted" },
            },
            {
                path: "deleted/:departmentId/:detailFormStatusId/:formId",
                name: "deletedFormId",
                component: () => import('../views/DeletedBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true,  viewDataTable: false , loadFormView: true  , name: "deleted" },
            },
            {
                path: "dropped/:departmentId",
                name: "droppedNormal",
                component: () => import('../views/DroppedBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, redirectName: "dropped", isFormView: true, viewDataTable: true , name: "dropped" },
            },
            {
                path: "dropped/:departmentId/:detailFormStatusId",
                name: "dropped",
                component: () => import('../views/DroppedBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true, viewDataTable: true , name: "dropped" },
            },
            {
                path: "dropped/:departmentId/:detailFormStatusId/:formId",
                name: "droppedFormId",
                component: () => import('../views/DroppedBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1, detailFormStatusId: 1}, isFormView: true,  viewDataTable: false, loadFormView: true  , name: "dropped"  },
            },
            {
                path: "sent/:departmentId",
                name: "sent",
                component: () => import('../views/SentBox.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1}, isFormView: true, viewDataTable: true, name: "sent" },
            },
            {
                path: "createOrg/:departmentId",
                name: "createOrg",
                component: () => import('../views/CreateOrg.vue'),
                meta: {  requiresAuth: true , requires: {departmentId: 1} , name: "createOrg"},
            },
            {
                path: "createDepartment/:departmentId",
                name: "createDepartment",
                component: () => import('../views/CreateDepartment.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1}  , name: "createDepartment"},
            },
            {
                path: "inviteUser/:departmentId",
                name: "inviteUser",
                component: () => import('../views/InviteUser.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1} , name: "inviteUser"},
            },
            {
                path: "changeRegistrationInfo/:departmentId",
                name: "changeRegistrationInfo",
                // component: () => import('../views/ChangeRegistrationInfo.vue'),
                component: () => import('../views/AllDepartmentUser.vue'),
                meta: {  requiresAuth: true, requires: {departmentId: 1} , name: "changeRegistrationInfo" },
            },
            {
                path: "allDepartmentUser",
                name: "allDepartmentUser",
                component: () => import('../views/AllDepartmentUser.vue'),
                meta: {  requiresAuth: true  , name: "allDepartmentUser"},
            },
            {
                path: "sentEmailView/:departmentId",
                name: "sentEmailView",
                component: () => import('../views/SentEmailView.vue'),
                meta: { requiresAuth: true, requires: {departmentId: 1} , name: "sentEmailView" },
            },
            {
                path: "setting",
                name: "setting",
                component: () => import('../views/Setting.vue'),
                meta: { requiresAuth: true , name: "setting" },
            },
            // {
            //     path: "workspace",
            //     name: "workspace",
            //     component: () => import('../views/AdminWorkspace.vue'),
            //     meta: { requiresAuth: true , name: "workspace" },
            // },
            {
                path: "logout",
                name: "logout",
                component: {
                    async beforeRouteEnter(to, from, next) {
                        const result = window.confirm(i18n.global.tc("logout.logoutConfirm"));
                        if(result) {
                            await store.dispatch("auth/logout")

                            next("/admin_auth_login_door")
                        } else {
                            next(false)
                        }
                    }
                }
            },
            {
                path: ':catchAll(.*)',
                redirect: '/'
            },
        ]
    },
    {
        path: '/plans',
        component: () => import(/* webpackChunkName: "about" */ '../views/plans/index.vue'),
        meta: { beforeRequiresAuth: true },
        redirect: "/plans/info",
        children: [
            {
                path: "info/:departmentId",
                name: "info",
                component: () => import('../views/plans/Info.vue'),
                meta: { beforeRequiresAuth: true },
            },
            {
                path: "upgrade/:departmentId/:productId",
                name: "upgrade",
                component: () => import('../views/plans/Upgrade.vue'),
                meta: { beforeRequiresAuth: true },
            },
            {
                path: ':catchAll(.*)',
                redirect: '/'
            },
        ]
    },
    {
        path: "/previewWindow",
        name: "previewWindow",
        component: () => import('../views/PreviewWindow.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: "/callback",
        name: "Callback",
        component: () => import('../views/Callback.vue'),
    },
    {
        path: "/adminManage",
        name: "AdminManage",
        component: () => import('../views/AdminManage.vue'),
        // name: "top"
    },
    {
        path: '/changeWorkerEmail',
        name: 'ChangeWorkerEmail',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChangeWorkerEmail.vue')
    },
    {
        path: '/changeWorkerPassword',
        name: 'ChangeWorkerPassword',
        component: () => import(/* webpackChunkName: "about" */ '../views/ChangeWorkerPassword.vue')
    },
    {
        path: '/door',
        name: 'Door',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Door.vue')
    },
    {
        path: '/doors/:departmentUUID/:linkUUID',
        name: 'Doors',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Door.vue')
    },
    {
        path: '/inputform',
        name: 'Inputform',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/InputForm.vue')
    },
    {
        path: '/inputforms/:departmentUUID/:linkUUID',
        name: 'Inputforms',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/InputForm.vue')
    },
    {
        path: '/inputforms_redirect',
        name: 'inputforms_redirect',
        component: () => import(/* webpackChunkName: "about" */ '../views/InputformsRedirect.vue')
    },
    {
        path: '/form_validation/:departmentUUID/:linkUUID',
        name: 'form_validation_doors',
        component: () => import(/* webpackChunkName: "about" */ '../views/FormValidationDoors.vue')
    },
    {
        path: '/form_pre_message/:departmentUUID/:linkUUID',
        name: 'form_pre_message',
        component: () => import(/* webpackChunkName: "about" */ '../views/PreFormMessage.vue')
    },
    {
        path: '/notfoundforms',
        name: 'notfoundforms',
        component: () => import( '../views/NotFoundForms.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/postedFormDoor',
        name: 'PostedFormDoor',
        component: () => import(/* webpackChunkName: "about" */ '../views/PostedFormDoor.vue'),
        // meta: { requiresAuth: true },
    },
    {
        path: '/postedFormScreen',
        name: 'PostedFormScreen',
        component: () => import(/* webpackChunkName: "about" */ '../views/PostedFormScreen.vue'),
        // meta: { requiresAuth: true },
    },
    // {
    //     path: '/policy',
    //     name: 'Policy',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/Policy.vue')
    // },
    {
        path: '/:catchAll(.*)',
        component: () => import('../views/NotFoundPage.vue'),
        meta: { title: '404' }
    },
]

const router = createRouter({
    history: createWebHistory(""), // mode: "history",
    routes
})
router.beforeEach(async (to, from, next) => {
    const params = new URLSearchParams(document.location.search.substring(1));
    if(params.get("force_fresh") == "true") {
        await store.dispatch("auth/logout")
        // store.commit("auth/logout")
        params.delete("force_fresh")
        const q = params.toString()
        location.search = "?" + q
        // alert("?" + q)
        next(false)
        return;
    }
    if(params.get("email_hint")) {
        const email = params.get("email_hint")
        store.commit("auth/setAuthPointHintAddParams", {email: email})
        params.delete("email_hint")
    }
    if(params.get("password_hint")) {
        const password = params.get("password_hint")
        store.commit("auth/setAuthPointHintAddParams", {password: password})
        params.delete("password_hint")
    }
    
    if (to.meta.beforeRequiresAuth) {
        await store.dispatch("userBranchInfo/setMemberList")
        await store.dispatch('routeStore/getRouteList', to.params.departmentId)
        await store.dispatch('routeStore/setActiveRouteName', to.meta.name)
        store.commit('editTemplate/setEdited', false)
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {

        store.commit("gridDataStore/setEditableForm", false)
        // if(to.params.departmentId == undefined) {
        //     router.push({name: to.name, params: {departmentId: "@"}})
        //     return
        // }

        if(to.meta.requires) {
            if(to.meta.requires.departmentId && to.params.departmentId == undefined) {
                // router.push({name: to.meta.redirectName ? to.meta.redirectName : to.name, params: {departmentId: "@"}})
                if(to.meta.requires.detailFormStatusId) {
                    router.push({name: to.meta.redirectName ? to.meta.redirectName : to.name, params: {departmentId: constants.AllDepartmentIdConstants, detailFormStatusId : to.params.detailFormStatusId ? to.params.detailFormStatusId: constants.DetailFormStatusIdConstants}})
                } else {
                    router.push({name: to.meta.redirectName ? to.meta.redirectName : to.name, params: {departmentId: constants.AllDepartmentIdConstants}})
                }
                // router.push({name: to.name, params: {departmentId: constants.AllDepartmentIdConstants}})
                return
            }

            if(to.meta.requires.detailFormStatusId && to.params.detailFormStatusId == undefined) {
                router.push({name: to.meta.redirectName ? to.meta.redirectName : to.name, params: {departmentId: to.params.departmentId ? to.params.departmentId : constants.AllDepartmentIdConstants, detailFormStatusId: constants.DetailFormStatusIdConstants}})
                return
            }
        // store.dispatch('routeStore/getRouteList', [{}, {}, true, true])
        // await store.dispatch('routeStore/getRouteList')
    
        store.commit("routeStore/setWaiting", true)
        }
    }
    next();

});

router.afterEach(async (to, from) => {
    if(to.name == "draft" || to.name == "draftInfo") {
        if(to.query.importFormUrl) {
            if (!await store.dispatch("auth/checkAuth")) {
                localStorage.after_init_redirect_url = window.location.href;
            }
            const importFormUrl = decodeURIComponent(to.query.importFormUrl)
            let loca = constants.local + "/inputforms/"
            loca = loca.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
            const reg = new RegExp(loca + "(.*)/(.*)");
            const matchedList = importFormUrl.match(reg)
            if (matchedList && matchedList.length == 3) {
                const originalDepartmentUUID = matchedList[1]
                const originalLinkUUID = matchedList[2]
                const res = await store.dispatch('gridDataStore/createNewTemplateFormByImport', {originalDepartmentUUID: originalDepartmentUUID, originalLinkUUID: originalLinkUUID})
                
                await store.dispatch('gridDataStore/reloadGridDataList')
                if (res.Success && res.Message) {
                    store.commit('toastStore/setText', res.Message)
                    store.commit('toastStore/setShow', true)
                    router.push({name: "draftInfo", params: {departmentId: to.params.departmentId, templateFormId: res.TemplateId}})
                } else {
                    router.push({name: to.meta.name, params: {departmentId: to.params.departmentId, templateFormId: to.params.templateFormId}})
                }

                return
            }

        }
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        await store.dispatch("userBranchInfo/setMemberList")
        const isActiveDepartmentId = await store.dispatch('userBranchInfo/setActiveDepartmentId', to.params.departmentId)

        if(!isActiveDepartmentId) {
            const params = {
                ...to.params,
                departmentId: constants.AllDepartmentIdConstants,
            }
            router.push({name: to.meta.name, params: params})
            return 
        }

        await store.dispatch('routeStore/getRouteList', {DepartmentId: to.params.departmentId})
        // await store.dispatch('routeStore/countForms', {DepartmentId: to.params.departmentId})
        await store.dispatch('routeStore/setActiveRouteName', to.meta.name)
        store.commit('editTemplate/setEdited', false)
        store.commit("routeStore/setWaiting", false)

        if (isMobile.isMobile()) {
            const isValidMobileRoute = await store.dispatch('routeStore/isValidMobileRoute')
            if (!isValidMobileRoute) {
                router.push({ name: "sent", params: {departmentId: constants.AllDepartmentIdConstants } });
                return
            }
        }



    }
    socketHelper.leaveByALLTemplateId()
    if(to.meta.loadTemplateFormView && to.params.templateFormId) {
        store.commit('gridDataStore/setShowSpinner', true)
        const res = await store.dispatch('editTemplate/setTemplateById', {departmentId: to.params.departmentId, id: to.params.templateFormId})
        if(res.Success) {
            await store.dispatch('gridDataStore/updateTemplateRow', {id: to.params.templateFormId})
            store.commit('setSelectedRowId', {id: to.params.templateFormId, Type: "Template"})
            store.commit('gridDataStore/setSelectedData', to.params.templateFormId)
            store.commit('gridDataStore/setEditableForm', true)
            const routeList = store.getters['routeStore/getRouteList']
            const route = routeList.find(x => x.index == 4)
            await store.dispatch("detailFormStatus/setDetailFormStatusList", {DepartmentId: to.params.departmentId, route: route})
        } else {
            if(res.Message) {
                store.commit('toastStore/setText', res.Message)
                store.commit('toastStore/setShow', true)
            }
            store.commit('setSelectedRowId', {id: "", Type: "Template"})
            store.commit('gridDataStore/setSelectedData', null)
            store.commit('gridDataStore/setEditableForm', false)
            router.push({name: to.meta.name, params: {departmentId: to.params.departmentId, templateFormId: ""}})
        }
        store.commit('gridDataStore/setShowSpinner', false)
        socketHelper.joinTemplate(to.params.templateFormId)
    } else if(to.meta.loadFormView && to.params.formId) {
        store.commit('gridDataStore/setShowSpinner', true)
        const res = await store.dispatch('viewPostedForm/getFormById', to.params.formId)
        if(res.Success) {
            await store.dispatch('gridDataStore/updateFormRow', {id: to.params.formId})
            store.commit('setSelectedRowId', {id: to.params.formId, Type: "Form"})
            store.commit('gridDataStore/setSelectedData', to.params.formId)
            store.commit('gridDataStore/setEditableForm', true)
        } else {
            if(res.Message) {
                store.commit('toastStore/setText', res.Message)
                store.commit('toastStore/setShow', true)
            }
            store.commit('setSelectedRowId', {id: "", Type: "Form"})
            store.commit('gridDataStore/setSelectedData', null)
            store.commit('gridDataStore/setEditableForm', false)
            // router.push({ name: to.meta.name })
        }
        store.commit('gridDataStore/setShowSpinner', false)
    } else {
        store.commit('gridDataStore/setSelectedData', null)
    }
    store.commit("gridDataStore/setViewDataTable", to.meta.viewDataTable)
});
export default router
