import http from '../mixins/api'
import constants from '@/mixins/constants';


export default {
    namespaced: true,
    state: {
        formUrl: "",
        shortInputTextDisabled: false,
        shortInputTextDisabled2: false,
        templateId: "",
        urls: {
            form: "",
            // formSkip: "",
            shortForm: "",
            // shortFormSkip: "",
        },
        isSkipEmailAuth: true,
        publicFormatType: "form"
    },
    getters: {
        getFormUrl(state) {
            return state.formUrl
        },
        getShortInputTextDisabled(state) {
            return state.shortInputTextDisabled
        },
        getFormUrl2(state) {
            return state.urls.form
        },
        getFormSkipUrl(state) {
            return state.urls.formSkip
        },
        getFormURLs(state) {
            return state.urls
        },
        getTemplateId(state) {
            return state.templateId
        },
        getShortInputTextDisabled2(state) {
            return state.shortInputTextDisabled2
        },
        getIsSkipEmailAuth(state) {
            return state.isSkipEmailAuth
        },
        getPublicFormatType(state) {
            return state.publicFormatType
        }
    },
    mutations: {
        setFormUrl(state, formUrl) {
            state.formUrl = formUrl
        },
        setShortInputTextDisabled(state, shortInputTextDisabled) {
            state.shortInputTextDisabled = shortInputTextDisabled
        },
        setURL(state, urls) {
            state.urls.form = urls.formURL
            // state.urls.formSkip = urls.formSkipURL
            state.urls.shortForm = urls.shortFormURL
            // state.urls.shortFormSkip = urls.shortFormSkipURL

        },
        setTemplateId(state, templateId) {
            state.templateId = templateId
        },
        setShortInputTextDisabled2(state, shortInputTextDisabled2) {
            state.shortInputTextDisabled2 = shortInputTextDisabled2
        },
        setIsSkipEmailAuth(state, isSkipEmailAuth) {
            state.isSkipEmailAuth = isSkipEmailAuth
        },
        setPublicFormatType(state, publicFormatType) {
            state.publicFormatType = publicFormatType
        },
    },
    actions: {
        async setShortcutFormURL(context, obj) {
            const url = obj.url
            const templateId = obj.templateId
            const skipEmailAuth = obj.skipEmailAuth
            context.commit('setShortInputTextDisabled', true)
            const res = await http.post("/form/GetShortcutURL", {templateId: templateId, url: url, skipEmailAuth: skipEmailAuth})
            if(res.Success) {
                context.commit("setFormUrl", res.Url)
            }
            context.commit('setShortInputTextDisabled', false)
        },

        async getFormURLs(context, params) {
            const templateId = params.templateId
            context.commit('setShortInputTextDisabled2', true)
            const res = await http.post("/form/GetFormURLs", {templateId: templateId})
            if(res.Success) {
                context.commit("setURL", res.URLs)
                context.commit("setTemplateId", templateId)
                context.commit("setIsSkipEmailAuth", res.IsSkipAuth)
                context.commit("setPublicFormatType", res.PublicFormatType)
            }
            context.commit('setShortInputTextDisabled2', false)

        },

        async setIsSkipEmailAuth(context, params) {
            const templateId = params.templateId
            context.commit('setShortInputTextDisabled2', true)
            const res = await http.post("/form/setIsSkipEmailAuth", {templateId: templateId, skipEmailAuth: params.skipEmailAuth})
            if(res.Success) {
                context.commit("setIsSkipEmailAuth", params.skipEmailAuth)
            }
            context.commit('setShortInputTextDisabled2', false)

            return res

        },

        async setPublicFormatType(context, params) {
            const templateId = params.templateId
            const res = await http.post("/form/SetPublicFormatType", {templateId: templateId, publicFormatType: params.publicFormatType})
            if(res.Success) {
                context.commit("setPublicFormatType", params.publicFormatType)
            }

            return res
        },


    }
}


