import http from '../mixins/api'
import { uuid } from "vue-uuid";
import store from "@/store"
import constants from '@/mixins/constants'

export default {
    namespaced: true,
    state: {
        Member: {
            List: [],
            SuggestionList: [],
            FilteredListByDepartment: [],
        },
        Department: {
            List: [],
            SuggestionList: [],
            FilteredListByCompany: [],
            IsPaidList: []
        },
        Company: {
            List: [],
            SuggestionList: [],
        },
        RegistrationData: [],
        RegistrationTreeData: [],
        VisibleIdList: [],
        Workspace: [],
        ActiveDepartmentId: constants.AllDepartmentIdConstants,
    },
    getters: {
        getPaidDepartmentList(state) {
            return state.Department.IsPaidList
        },
        getFilteredListByDepartment(state) {
            return state.Member.FilteredListByDepartment
        },
        getFilteredListByCompany(state) {
            return state.Department.FilteredListByCompany
        },
        getVisibleIdList(state) {
            return state.VisibleIdList
        },
        getMemberList(state) {
            return state.Member.List
        },
        getDepartmentList(state) {
            return state.Department.List
        },
        getBelongDepartmentList(state) {
            const userInfo = store.getters["auth/getUserInfo"]
            if(userInfo) {
                const _id = userInfo._id
                const members = state.Member.List.filter(x => x.WorkUserId == _id)
                const List = state.Department.List.filter(l => members.map(m => m.DepartmentId).includes(l._id))

                return List
            } else {
                return []
            }
        },
        getCompanyList(state) {
            return state.Company.List
        },
        getMemberListInSuggesting(state) {
            return state.Member.SuggestionList
        },
        getDepartmentListInSuggesting(state) {
            return state.Department.SuggestionList
        },
        getCompanyListInSuggesting(state) {
            return state.Company.SuggestionList
        },
        getRegistrationInfoData(state) {
            return state.RegistrationData
        },
        getRegistrationTreeInfoData(state) {
            return state.RegistrationTreeData;
        },
        getWorkspace(state) {
            return state.Workspace
        },
        getActiveDepartmentId(state) {
            return state.ActiveDepartmentId
        },
        getOwnDepartmentIdList(state) {
            let DepartmentList = state.Department.List
            let MemberList = state.Member.List
            const userInfo = store.getters["auth/getUserInfo"]
            const _id = userInfo._id
            const ownList = MemberList.filter(x => x.WorkUserId == _id)
            const ownDepartmentIdList = Array.from(new Set(ownList.map(x => x.DepartmentId)))

            return ownDepartmentIdList
        },
    },
    mutations: {
        setFilteredListByDepartment(state, Department) {
            const List = state.Member.List
            const filtered = List.filter(l => l.DepartmentId == Department._id)
            state.Member.FilteredListByDepartment.splice(0, state.Member.FilteredListByDepartment.length, ...filtered)
        },
        setFilteredListByCompany(state, Company) {
            const List = state.Department.List
            const filtered = List.filter(l => l.CompanyId == Company._id)
            state.Department.FilteredListByCompany.splice(0, state.Department.FilteredListByCompany.length, ...filtered)
        },
        setMemberList(state, _id) {
            state.info._id = _id
        },
        setMemberListByQuery(state, query) {
            const regexp = new RegExp('.*' +query + '.*', 'gi')
            state.Member.SuggestionList = state.Member.List.filter( member => member.Name && regexp.test(member.Name) )
        },
        setDepartmentListByQuery(state, query) {
            const regexp = new RegExp('.*' +query + '.*', 'gi')
            const SuggestionList = state.Department.List.filter( x => x.Name && x.Name.match(regexp) )
            state.Department.SuggestionList.splice(0, state.Department.SuggestionList.length, ...SuggestionList)
        },
        setCompanyListByQuery(state, query) {
            const regexp = new RegExp('.*' + query + '.*', 'gi')
            const suggestionList = state.Company.List.filter(x => x.Name && x.Name.match(regexp))
            state.Company.SuggestionList.splice(0, state.Company.SuggestionList.length, ...suggestionList)
        },
        setRegistrationInfoData(state) {
            const MemberList = state.Member.List
            const DepartmentList = state.Department.List
            const RegistrationData = []
            for(let i = 0; i < MemberList.length; i++) {
                const Member = MemberList[i]
                const Department = DepartmentList.find(x => x._id == Member.DepartmentId)
                Member.Department = Department
                RegistrationData.push(Member)
            }
            state.RegistrationData.splice(0, state.RegistrationData.length, ...RegistrationData)
        },
        setRegistrationTreeInfoData(state) {
            const MemberList = state.Member.List
            const DepartmentList = state.Department.List
            let CompanyList = state.Company.List
            const Data = []
            const userInfo = JSON.parse(localStorage.userInfo)

            for(let i = 0; i < CompanyList.length; i++) {
                Data.push({
                    key: i,
                    data: {
                        _id: CompanyList[i]._id,
                        Name: CompanyList[i].Name,
                        IsCompany: true,
                        IsInitName: CompanyList[i].Name
                    },
                })
            }

            for(let i = 0; i < Data.length; i++) {
                const company = Data[i].data
                let department = DepartmentList.filter(department => department.CompanyId == company._id)
                let numDepartmentCodes = department.filter(x => !isNaN(parseInt(x.DepartmentCode)))
                let textDepartmentCodes = department.filter(x => isNaN(parseInt(x.DepartmentCode)))
                numDepartmentCodes = numDepartmentCodes.sort(function(a, b) {
                    let a_num = parseInt(a.DepartmentCode)
                    let b_num = parseInt(b.DepartmentCode)
                    if(a_num > b_num) return 1;
                    if(a_num < b_num) return -1;
                    return 0;
                });
                textDepartmentCodes = textDepartmentCodes.sort(function(a, b) {
                    if(a.DepartmentCode > b.DepartmentCode) return 1;
                    if(a.DepartmentCode < b.DepartmentCode) return -1;
                    return 0;
                });
                department = [...numDepartmentCodes, ...textDepartmentCodes]

                const departmentIdList = department.map(x => x._id)
                const member = MemberList.filter(mem => departmentIdList.includes(mem.DepartmentId))
                const children = []
                for(let j = 0; j < department.length; j++) {
                    let isBelong = false
                    const member = MemberList.filter(mem => department[j]._id == mem.DepartmentId).map(x => {
                        x.IsMember = true
                        if(x.WorkUserId == userInfo._id) isBelong = true
                        return {
                            key: Data[i].key + "-" + j + "-" + j ,
                            data: x,
                        }
                    })
                    children.push({
                        key: Data[i].key + "-" + j,
                        data: {
                            _id: department[j]._id,
                            Name: department[j].Name,
                            DepartmentCode: department[j].DepartmentCode,
                            IsDepartment: true,
                            IsOPE: department[j].IsOPE,
                            IsInitName: department[j].Name,
                            IsBelong: isBelong
                        },
                        children: member
                    })
                }
                Data[i].children = children
            }
            state.RegistrationTreeData = Data
        },
        setWorkspace(state) {
            let DepartmentList = state.Department.List
            let MemberList = state.Member.List
            const userInfo = store.getters["auth/getUserInfo"]
            const _id = userInfo._id
            const ownList = MemberList.filter(x => x.WorkUserId == _id)
            const ownDepartmentIdList = Array.from(new Set(ownList.map(x => x.DepartmentId)))
            const CompanyList = state.Company.List
            DepartmentList = DepartmentList.filter(x => ownDepartmentIdList.includes(x._id))
            for (let i = 0; i < DepartmentList.length; i++) {
                const Company = CompanyList.find(x => x._id == DepartmentList[i].CompanyId) || {}
                DepartmentList[i].DisplayName = DepartmentList[i].Name + `(${Company.Name ? Company.Name : ''})`
            }
            state.Workspace = DepartmentList
        },
    },

    actions: {
        setActiveDepartmentId({state, getters}, ActiveDepartmentId) {
            const ownDepartmentIdList = getters["getOwnDepartmentIdList"]
            state.ActiveDepartmentId = ownDepartmentIdList.includes(ActiveDepartmentId) ? ActiveDepartmentId : constants.AllDepartmentIdConstants

            return ownDepartmentIdList.includes(ActiveDepartmentId) || constants.AllDepartmentIdConstants == ActiveDepartmentId
        },
        async setMemberList ({context, commit, state}, params=[{}, {}, true, true]) {
            const res = await http.post("/user/GetUserBranchesInfo", params[0], params[1], params[2], params[3])
            if(res.Success) {
                state.Member.List = res.MemberList
                state.Department.List = res.DepartmentList
                state.Company.List = res.CompanyList
                state.VisibleIdList = res.VisibleIdList
                state.Department.IsPaidList = res.IsPaidDepartmentList
                commit('setWorkspace')
            }
        },

        async getAllUserBranchesInfo({}) {  // 旧workspace用
            const res = await http.post("/user/GetAllUserBranchesInfo")
            const Data = []
            let ActiveWorkDepartmentIdList = []
            if(res.Success) {
                ActiveWorkDepartmentIdList = res.ActiveWorkDepartmentIdList
                const MemberList = res.MemberList
                const DepartmentList = res.DepartmentList
                const CompanyList = res.CompanyList
                for(let i = 0; i < CompanyList.length; i++) {
                    Data.push({
                        key: i,
                        data: {
                            _id: CompanyList[i]._id,
                            Name: CompanyList[i].Name,
                            IsCompany: true,
                            IsInitName: CompanyList[i].Name
                        },
                    })
                }

                for(let i = 0; i < Data.length; i++) {
                    const company = Data[i].data
                    const department = DepartmentList.filter(department => department.CompanyId == company._id)
                    const departmentIdList = department.map(x => x._id)
                    const member = MemberList.filter(mem => departmentIdList.includes(mem.DepartmentId))
                    const children = []
                    for(let j = 0; j < department.length; j++) {
                        const member = MemberList.filter(mem => department[j]._id == mem.DepartmentId).map(x => {
                            x.IsMember = true
                            return {
                                key: Data[i].key + "-" + j + "-" + j ,
                                data: x,
                            }
                        })
                        children.push({
                            key: Data[i].key + "-" + j,
                            data: {
                                _id: department[j]._id,
                                Name: department[j].Name,
                                IsDepartment: true,
                                IsOPE: department[j].IsOPE,
                                IsInitName: department[j].Name
                            },
                            children: member
                        })
                    }
                    Data[i].children = children
                }
            }
            return {ActiveWorkDepartmentIdList, Data};
        },

        async setActiveWorkspaces({}, params) {
            const ActiveWorkDepartmentIdList = params.ActiveWorkDepartmentIdList
            const res = await http.post("/user/SetActiveWorkspaces", {ActiveWorkDepartmentIdList: ActiveWorkDepartmentIdList})

            return res

        }
    }
}
