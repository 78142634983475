// import axiosBase from 'axios'
// // import authApi from "@/mixins/authApi"
// import helper from "@/mixins/helper"
// import constants from './constants'
// import store from '@/store';
// import i18n from '@/lang'
//
// export default {
//     name: 'http',
//     created() {
//     },
//
//     data() {
//         return {
//             serverPass: "https://localhost:10443/",
//         }
//     },
//     async beforeAuthPost(params={}, isReAuth=true) {
//         const url = constants.baseUrl + "/user/CheckTokenBefore"
//         let res = null
//         const axios = axiosBase.create({ })
//         const headers = null
//
//         axios.interceptors.request.use((request)=>{
//             const token = store.getters["auth/getJwt"]
//             request.headers['Authorization'] = `Bearer ${token}`
//             request.headers['withCredentials'] = true
//             request.headers['Accept-Language'] = `${i18n.global.locale}`
//
//
//             return request;
//         })
//
//         const response = await axios.post(url, params, { withCredentials: true })
//         res = response.data
//         if(res.Success) {
//             if(res.newToken) {
//                 alert(res.newToken)
//                 store.commit("auth/setToken", res.newToken)
//             }
//             if(res.userInfo) {
//                 store.commit("auth/setUserInfo", res.userInfo)
//                 i18n.global.locale = res.userInfo.language;
//                 store.commit("auth/setUserInfoLanguage", res.userInfo.language)
//             }
//         }
//         if(!res.Success) {
//             if(isReAuth){
//                 const href = window.location.href
//                 let redirect_url = helper.trimToURL(["force_fresh", "password_hint"], href)
//                 await store.dispatch("auth/logout")
//                 await store.dispatch("auth/authPoint", {redirect_url: redirect_url})
//             }
//         }
//         return res
//     },
//
//     async post(url, params, headers={}, addToken=true, beforeAuth=true) {
//         // console.log(url, params, headers, addToken, beforeAuth)
//
//
//         if(beforeAuth) {
//             // const authRes = await this.beforeAuthPost()
//         }
//
//         const baseUrl = constants.baseUrl
//         const authLoginBaseUrl = constants.authLoginBaseUrl
//         let res = null
//         const axios = axiosBase.create({ })
//         axios.interceptors.request.use((request)=>{
//             if(addToken) {
//                 const token = store.getters["auth/getJwt"]
//                 request.headers['Authorization'] = `Bearer ${token}`
//                 request.headers['withCredentials'] = true
//                 request.headers['Accept-Language'] = `${i18n.global.locale}`
//
//
//             }
//
//             return request;
//         })
//
//         if(!headers) {
//             headers = {}
//         }
//         headers["withCredentials"] = true
//         await axios.post(baseUrl + url, params, headers).then(response => {
//             res = response.data
//         }).catch((err) => {})
//         if(!res.Success && res.TOKENERROR) {
//             await this.beforeAuthPost()
//             res = await this.post(url, params, headers, addToken, beforeAuth)
//         }
//         return res
//     },
//
//
//     computed: {
//         twoBytwo() {
//             return 2 * 2
//         }
//     }
// }
//
//
//










import axiosBase from 'axios'
// import authApi from "@/mixins/authApi"
import helper from "@/mixins/helper"
import constants from './constants'
import store from '@/store';
import i18n from '@/lang'
import router from '@/router'


const axios = axiosBase.create({ })
const axios4Auth = axiosBase.create({ })
axios.interceptors.request.use((request)=>{
    const token = store.getters["auth/getJwt"]
    request.headers['Authorization'] = `Bearer ${token}`
    request.headers['withCredentials'] = true
    request.headers['Accept-Language'] = `${i18n.global.locale}`
    const departmentId = router.currentRoute.value.params ? router.currentRoute.value.params.departmentId : ""
    const detailFormStatusId = router.currentRoute.value.params ? router.currentRoute.value.params.detailFormStatusId : ""

    request.headers['Active-DepartmentId'] = departmentId
    request.headers['Active-DetailFormStatusId'] = detailFormStatusId

    // const TABUUID = sessionStorage.getItem('TABUUID')
    const TABUUID = localStorage.getItem('TABUUID')
    request.headers['Active-TABUUID'] = TABUUID

    return request;
})
axios4Auth.interceptors.request.use((request)=>{
    const token = store.getters["auth/getJwt"]
    request.headers['Authorization'] = `Bearer ${token}`
    request.headers['withCredentials'] = true
    request.headers['Accept-Language'] = `${i18n.global.locale}`
    return request;
})

const beforeAuthPost = async (params={}, isReAuth=true) => {
    const url = constants.baseUrl + "/user/CheckTokenBefore"
    let res = null
    const headers = null

    const response = await axios4Auth.post(url, params, { withCredentials: true })
    res = response.data
    // alert(JSON.stringify(res))
    if(res.Success) {
        if(res.newToken) {
            // alert(res.newToken)
            store.commit("auth/setToken", res.newToken)
        }
        if(res.userInfo) {
            store.commit("auth/setUserInfo", res.userInfo)
            if(i18n.global.locale != res.userInfo.language) {
                i18n.global.locale = res.userInfo.language;
                store.commit("auth/setUserInfoLanguage", res.userInfo.language)
                store.commit("routeStore/setLoaded", false)
            }
        }
    } else {
        if(isReAuth) {
            const href = window.location.href
            let redirect_url = helper.trimToURL(["force_fresh", "password_hint"], href)
            await store.dispatch("auth/logout")
            store.commit("auth/logout")
            await store.dispatch("auth/authPoint", {redirect_url: redirect_url})
        }
    }
    return Promise.resolve(res)
}


let refreshTokenPromise;

axios.interceptors.response.use(r => {
    // alert(JSON.stringify(r.config))
    // alert(JSON.stringify(r))
    if(r.headers && r.headers["content-locale-paper"]) {
        if(i18n.global.locale != r.headers["content-locale-paper"]) {
            i18n.global.locale = r.headers["content-locale-paper"];
            store.commit("auth/setUserInfoLanguage", r.headers["content-locale-paper"])
            store.commit("routeStore/setLoaded", false)
            const departmentId = router.currentRoute.value.params ? router.currentRoute.value.params.departmentId : constants.AllDepartmentIdConstants
            store.dispatch('routeStore/getRouteList', {DepartmentId: departmentId})
        }
    }
    if(r.headers && r.headers["content-timezone-paper"]) {
        store.commit("auth/setUserInfoTimezone", r.headers["content-timezone-paper"])
    }
    if(r.headers && r.headers["content-dateformat-paper"]) {
        store.commit("auth/setUserInfoDateformat", r.headers["content-dateformat-paper"])
    }
    if(r.headers && r.headers["content-email-paper"]) {
        store.commit("auth/setUserInfoEmail", r.headers["content-email-paper"])
    }
    if(r.data.BELONGERROR) {
        store.dispatch("auth/logout")
    } else if (r.data.TOKENERROR && r.config && !r.config._retry) {
        // alert(refreshTokenPromise)
        // alert(Boolean(refreshTokenPromise))
        if (!refreshTokenPromise) {
            r.config._retry = true;

            refreshTokenPromise = beforeAuthPost().then(token => {
                // alert("token")
                // alert(JSON.stringify(token))
                refreshTokenPromise = null
                return token
            })
        }
        // await axios.request(r.config)
        return refreshTokenPromise.then(token => {
            // alert(r.config.url, JSON.parse(r.config.data))
            if(token.Success) {
                r.config.headers['Authorization'] = `Bearer ${token.newToken}`
                if(r.config.data) {
                    console.log(r.config["content-type"])
                    if(r.config["content-type"] == "multipart/form-data") {
                        return axios.post(r.config.url, r.config.data, r.config.headers)
                    } else {
                        return axios.post(r.config.url, JSON.parse(r.config.data), r.config.headers)
                    }
                } else {
                    return axios.post(r.config.url, {}, r.config.headers)
                }
            }
            // return axios.request(r.config)
        })

    }

    return Promise.resolve(r)
})



export default {
    name: 'http',
    async beforeAuthPost(params, isReAuth=true) {
        // alert(isReAuth)
        return await beforeAuthPost(params, isReAuth)
    },

    async post(url, params, headers={}, addToken=true, beforeAuth=true) {
        const baseUrl = constants.baseUrl

        if(!addToken) {
            const axios1 = axiosBase.create({ })
            const respon = await axios1.post(baseUrl + url, params, { withCredentials: true })
            return respon.data
        }

        if(beforeAuth) {
        }

        let res = null


        if(!headers) {
            headers = {}
        }
        headers["withCredentials"] = true
        const response = await axios.post(baseUrl + url, params, headers)
        res = response.data


        return res
    },

    async get(url, params, headers={}, addToken=true, beforeAuth=true) {
        const baseUrl = constants.baseUrl

        if(!addToken) {
            const axios1 = axiosBase.create({ })
            const respon = await axios1.get(url, params, { withCredentials: true })
            return respon.data
        }

        let res = null


        if(!headers) {
            headers = {}
        }
        headers["withCredentials"] = true
        const response = await axios.get(url, params, headers)
        res = response.data


        return res
    },

}
