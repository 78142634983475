// import moment from "moment";
import store from '@/store';
import * as moment from 'moment-timezone';

// moment.tz.setDefault('Asia/Tokyo');

export default {
    formatDate(value, format=null, addFormat=null) {
        const userInfo = store.getters["auth/getUserInfo"]
        const dateFormat = userInfo && userInfo.dateformat ? userInfo.dateformat : "YYYY/MM/DD"
        const timeZone = userInfo && userInfo.timezone ? userInfo.timezone.split('-')[0].split('+')[0] : "Asia/Tokyo"
        if(value) {
            if(!format) {
                format = dateFormat
            }
            if(addFormat) {
                format = format + " " + addFormat;
            }
            const result = moment(value).tz(timeZone).format(format)
            return result;
            // return moment.tz(value, format, "Asia/Tokyo");
        }

        return ""
    },
    formatDateAtDate(value) {
        const userInfo = store.getters["auth/getUserInfo"]
        const dateFormat = userInfo ? userInfo.dateformat : "YYYY/MM/DD"
        const timeZone = userInfo ? userInfo.timezone.split('-')[0].split('+')[0] : "Asia/Tokyo"
        const yesterday = moment().add(-1, 'day').endOf('day')
        const today = moment().endOf('day')
        if(value) {
            if(yesterday < moment(value) && moment(value) < today) {
                return moment(value).tz(timeZone).format("hh:mm A");
            }
            return moment(value).tz(timeZone).format(dateFormat);
        }

        return ""
    },
    changeTZ(value, tz="", toDate=false) {
        if(value) {
            const userInfo = store.getters["auth/getUserInfo"]
            const timeZone = userInfo ? userInfo.timezone.split('-')[0].split('+')[0] : "Asia/Tokyo"
            if (toDate) {
                return moment(value).tz(tz ? tz : timeZone).toDate();
            }
            return moment(value).tz(tz ? tz : timeZone).format('yyyy/MM/DD HH:mm');
        }
    },
    startOf(value, option="hours") {
        return moment(value).startOf(option)
    },
    endOf(value, option="hours") {
        return moment(value).endOf(option)
    },
}
