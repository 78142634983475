import http from '../mixins/api'
import { uuid } from "vue-uuid";
import helper from '@/mixins/helper';
import * as moment from 'moment-timezone';

export default {
    namespaced: true,
    state: {
        formTemplateId: null,
        formState: {
            allFormList: [],
            activeUserAttr: "",
            appType: [],
            activeForm: []
        },
        AllAppTypeList: [],
        userAttrList: [],
        allUserAttrList: [],
        fixingList: [],
        files: [],
        templateInfo: {},
        change_id: "",
        editindDataList: [],
        MaxMBFileSize: 2,
        descriptionCopyEmail: "",
        savingData2LocalStorage: false,
        savingData2LocalStorageNumber: 0,
        storeAutoSaveData: [],
    },
    getters: {
        getFormTemplateId(state) {
            return state.formTemplateId
        },
        getDepartmentId(state) {
            return state.templateInfo.DepartmentId
        },
        getAppType(state) {
            return state.formState.appType
        },
        getActiveFormValues(state) {
            const activeForm = state.formState.activeForm
            return activeForm
        },
        getAllFormValues(state) {
            const allFormList = state.formState.allFormList
            return allFormList
        },
        getActiveUserAttr(state) {
            return state.formState.activeUserAttr
        },
        getActiveAppTypeList(state) {
            const list = state.AllAppTypeList.filter(x => x.userAttr == state.formState.activeUserAttr)
            if(list){ 
                return list
            }
            return []
        },
        getUserAttrList(state) {
            return state.userAttrList;
        },
        getActiveForm(state) {
            const formList = state.formState.allFormList.find(x => x.userAttr == state.formState.activeUserAttr)
            if(formList) {
                return formList.formList
            }
        },
        getDocumentName(state) {
            return state.templateInfo.DocumentName
        },
        getFiles(state) {
            return state.files
        },
        getChange_Id(state) {
            return state.change_id
        },
        getEditindDataList(state) {
            return state.editindDataList
        },
        getMBFileSize(state) {
            const file_uuidList = state.formState.activeForm.filter(x => x.File).map(y => y._uuid)
            const files = state.files.filter(file => file_uuidList.includes(file._uuid))

            return files.map(x => x.size ? x.size : 0).reduce((sum, ele) => {return sum + ele}, 0) / 1024 / 1024
        },
        getMaxMBFileSize(state) {
            return state.MaxMBFileSize
        },
        getDescriptionCopyEmail(state) {
            return state.descriptionCopyEmail
        },
        getSavingData2LocalStorage(state) {
            return state.savingData2LocalStorage;
        },
        getSavingData2LocalStorageNumber(state) {
            return state.savingData2LocalStorageNumber;
        },

    },
    mutations: {
        setTemplateInfo(state, info) {
            state.templateInfo = info
        },
        setFixField(state, obj) {
            const index = obj.index
            const item = obj.item
            const formList = state.formState.allFormList.find(x => x.userAttr == state.formState.activeUserAttr)
            if(formList) {
                const fix = !Boolean(formList.formList[index].fix)
                for(let i = 0; i < formList.formList[index].items.length; i++) {
                    formList.formList[index].items[i].fix = fix
                    if(!fix) {
                        let data = state.fixingList.find(x => x._id == formList.formList[index].items[i]._id)
                        if(data) {
                            if(data.hasOwnProperty("Value")) {
                                formList.formList[index].items[i].Value = data.Value
                            }
                            if(data.hasOwnProperty("LabelList")) {
                                formList.formList[index].items[i].LabelList = data.LabelList
                            }
                            if(data.hasOwnProperty("ValueList")) {
                                formList.formList[index].items[i].ValueList = data.ValueList
                            }
                            if(data.hasOwnProperty("DateValue")) {
                                formList.formList[index].items[i].DateValue = data.DateValue
                            }
                        }
                    }
                }
                state.change_id = item._id
                formList.formList[index].fix = fix
            }
        },
        setAppType(state, appType) {
            for(let i = 0; i < state.AllAppTypeList.length; i++) {
                if(state.AllAppTypeList[i].userAttr == state.formState.activeUserAttr) {
                    if(appType == state.AllAppTypeList[i].value) {
                        state.AllAppTypeList[i].checked = true
                    } else {
                        state.AllAppTypeList[i].checked = false
                    }
                }
            }
            state.formState.appType = appType
        },
        formUpdate(state, list) {
            for (let listIndex = 0; listIndex < list.length; listIndex++) {
                const data = list[listIndex]
                const activeUserAttr = state.formState.activeUserAttr
                const formIndex = state.formState.allFormList.findIndex(x => x.userAttr == activeUserAttr)
                if(formIndex == -1) {
                    state.formState.allFormList.push({ userAttr: activeUserAttr, formList: [] })
                }
                let formList = state.formState.allFormList.find(x => x.userAttr == activeUserAttr).formList
                let activeForm = []
                if(formList) {
                    for(let i = 0; i < formList.length; i++) {
                        for(let j = 0; j < formList[i].items.length; j++) {
                            if(formList[i].items[j]._id == data._id) {
                                if(data.Xtype == "fileField") {
                                    const _uuid = uuid.v4()
                                    data.File.FileName = data.File.name
                                    data.File.DirName = state.formTemplateId
                                    data.File._uuid = _uuid


                                    formList[i].items[j] = {
                                        _id: data._id,
                                        Xtype: "fileField",
                                        ComponentId: data.ComponentId,
                                        ItemId: data.ItemId,
                                        _uuid: _uuid,
                                        fix: data.fix,
                                        File: {
                                            FileName: data.File.name,
                                            _uuid: _uuid,
                                        }
                                    }
                                    if (data.File.name) {
                                        state.files.push(data.File)
                                    }
                                } else {
                                    formList[i].items[j] = data
                                }
                            }
                            activeForm.push(formList[i].items[j])
                        }
                    }
                    state.formState.activeForm = activeForm
                    state.change_id = data._id
                }
            }
        },
        storeAutoSaveData(state, data) {
            state.storeAutoSaveData.push(data)
        },
        savingUserAttrAndAppType2LocalStorage(state) {
            let inputtingData = {}
            const inputtingDataList = localStorage.inputtingDataList ? JSON.parse(localStorage.inputtingDataList) : []
            const existIndex = inputtingDataList.findIndex(x => x.formTemplateId == state.formTemplateId)
            if(existIndex != -1) {
                inputtingData = inputtingDataList[existIndex]
                inputtingData.activeUserAttr = state.formState.activeUserAttr
                inputtingData.appType = state.formState.appType
            } else {
                inputtingData =  {
                    formTemplateId: state.formTemplateId,
                    activeUserAttr: state.formState.activeUserAttr,
                    appType: state.formState.appType,
                    formDataList: []
                }
            }
            if(existIndex != -1) {
                inputtingDataList[existIndex] = inputtingData
            } else {
                inputtingDataList.push(inputtingData)
            }
            localStorage.inputtingDataList = JSON.stringify(inputtingDataList)
        },
        savingData2LocalStorage(state) {
            let inputtingData = {}
            const inputtingDataList = localStorage.inputtingDataList ? JSON.parse(localStorage.inputtingDataList) : []
            const storedDataList = state.storeAutoSaveData
            if(!storedDataList.length) return;

            const existIndex = inputtingDataList.findIndex(x => x.formTemplateId == state.formTemplateId)
            if(existIndex != -1) {
                inputtingData = inputtingDataList[existIndex]
            } else {
                inputtingData =  {
                    formTemplateId: state.formTemplateId,
                    activeUserAttr: state.formState.activeUserAttr,
                    appType: state.formState.appType,
                    formDataList: []
                }
            }
            const formDataList = inputtingData.formDataList
            for(let i = 0; i <  storedDataList.length; i++) {
                const storedData = storedDataList[i]
                const index = formDataList.findIndex(x => x._id == storedData._id) 
                if(index != -1) {
                    formDataList[index] = storedData
                } else {
                    formDataList.push(storedData)
                }
            }
            inputtingData.formDataList = formDataList
            if(existIndex != -1) {
                inputtingDataList[existIndex] = inputtingData
            } else {
                inputtingDataList.push(inputtingData)
            }

            localStorage.inputtingDataList = JSON.stringify(inputtingDataList)
        },
        setInitFormData(state, formData) {
            const formDataList = formData.formDataList
            const allFormList = state.formState.allFormList

            const allFormListFlat = state.formState.allFormList.flatMap(x => x.formList)
            if(allFormListFlat) {
                for(let i = 0; i < allFormListFlat.length; i++) {
                    for(let j = 0; j < allFormListFlat[i].items.length; j++) {
                        const savedIndex = formDataList.findIndex(x => x._id == allFormListFlat[i].items[j]._id)
                        if(savedIndex != -1) {
                            const Xtype = allFormListFlat[i].items[j].Xtype
                            if(Xtype == "checkBoxGroupField" || Xtype == "multiSelectField") {
                                allFormListFlat[i].items[j].ValueList = formDataList[savedIndex].ValueList
                            } else if(Xtype == "timeField" || Xtype == "dateField" || Xtype == "datetimeField"){
                                if (formDataList[savedIndex].DateValue) {
                                    allFormListFlat[i].items[j].DateValue = new Date(formDataList[savedIndex].DateValue)
                                }
                            } else {
                                allFormListFlat[i].items[j].Value = formDataList[savedIndex].Value
                            }
                        }
                    }
                }
            }

            state.formState.activeUserAttr = formData.activeUserAttr
            state.formState.appType = formData.appType
        },
        clearInitFormData(state, formTemplateId) {
            const inputtingDataList = localStorage.inputtingDataList ? JSON.parse(localStorage.inputtingDataList) : []
            const existIndex = inputtingDataList.findIndex(x => x.formTemplateId == formTemplateId)
            if(existIndex != -1) {
                inputtingDataList.splice(existIndex, 1)
                localStorage.inputtingDataList = JSON.stringify(inputtingDataList)
            }
        },
        setActiveUserAttr(state, attr) {
            const nextActiveFormList = state.formState.allFormList.find(x => x.userAttr == attr)
            if(nextActiveFormList) {
                const nextActiveForm = []
                state.formState.activeForm = []
                for(let i = 0; i < nextActiveFormList.formList.length; i++) {
                    nextActiveForm.push(...nextActiveFormList.formList[i].items)
                }
                state.formState.activeForm.splice(0, state.formState.activeForm.length, ...nextActiveForm)
            } else {
                state.formState.activeForm = []
            }

            state.formState.activeUserAttr = attr
        },
        setFormTemplateId(state, formTemplateId) {
            state.formTemplateId = formTemplateId
        },
        setUserAttrData(state, language) {
            const allUserAttrList = state.allUserAttrList
            const userAttrList = allUserAttrList.filter(x => x.language == language)
            state.userAttrList.splice(0, state.userAttrList.length, ...userAttrList)
        },
        setAllUserAttrData(state, userAttrList) {
            state.allUserAttrList.splice(0, state.allUserAttrList.length, ...userAttrList)
        },
        setAppTypeData(state, appTypeList) {
            state.AllAppTypeList.splice(0, state.AllAppTypeList.length, ...appTypeList)
        },
        setFormListData(state, formListData) {
            const allFormList = state.formState.allFormList
            for(let i = 0; i < formListData.length; i++) {
                let formList = []
                for (let j = 0; j < formListData[i].items.length; j++) {
                    formList.push(formListData[i].items[j])
                }
                allFormList.push({
                    userAttr: formListData[i].userAttr, formList: formList
                })
            }
            
        },

        fixingDataInItem(state, data) {
            let index = state.fixingList.findIndex(x => x._id == data._id)
            let del = 1
            if(index == -1) {
                index = 0;
                del = 0
            }
            state.fixingList.splice(index, del, data)
        },

        clearFixingDataInItem(state) {
            state.fixingList.splice(0, state.fixingList.length)
        },

        setEditindDataList(state, data) {
            const editindDataList = state.editindDataList
            let added = false
            for (let i = 0; i < editindDataList.length; i++) {
                const _id = editindDataList[i]._id;
                if(_id == data._id) {
                    editindDataList[i] = data;
                    added = true
                }
            }
            if(added == false) {
                editindDataList.push(data)
            }
        },
        
        setMaxMBFileSize(state, max) {
            state.MaxMBFileSize = max
        },

        setDescriptionCopyEmail(state, descriptionCopyEmail) {
            state.descriptionCopyEmail = descriptionCopyEmail
        },
        setSavingData2LocalStorage(state, savingData2LocalStorage) {
            state.savingData2LocalStorage = savingData2LocalStorage;
        },
        setSavingData2LocalStorageNumber(state, savingData2LocalStorageNumber) {
            state.savingData2LocalStorageNumber = savingData2LocalStorageNumber;
        },
    },
    actions: {
        async sendFormData({context, state}, params) {
            const formUserToken = localStorage.formUserToken 
            const skipEmailAuth = params.skipEmailAuth
            const DepartmentUUID = params.DepartmentUUID
            const LinkUUID = params.LinkUUID

            const activeForm = state.formState.activeForm
            const activeUserAttr = state.formState.activeUserAttr
            const activeAppType = state.formState.appType
            const file_uuidList = state.formState.activeForm.filter(x => x.File).map(y => y._uuid)
            // const files = activeForm.filter(x => x.File).map(y => y.File)
            const files = state.files.filter(file => file_uuidList.includes(file._uuid))
            // const browserLanguage = helper.getBrowserLanguage()
            const browserLanguage = params.language 
            const browserTimezoneOffset = params.browserTimezoneOffset

            for (let i = 0; i < activeForm.length; i++) {
                if (activeForm[i].Xtype == "dateField" || activeForm[i].Xtype == "timeField" || activeForm[i].Xtype == "datetimeField") {
                    if (activeForm[i].DateValue) {
                        activeForm[i].DateStringValue = moment(activeForm[i].DateValue).format('YYYY/MM/DD HH:mm:ss')
                    }
                }

            }

            const data = {
                // formId: state.formId,
                formTemplateId: state.formTemplateId,
                AppType: activeAppType,
                UserAttr: activeUserAttr,
                Form: activeForm,
                DirName: state.formTemplateId,
                formUserToken: formUserToken,
                skipEmailAuth: skipEmailAuth,
                DepartmentUUID: DepartmentUUID,
                LinkUUID: LinkUUID,
                browserLanguage: browserLanguage,
                browserTimezoneOffset: browserTimezoneOffset,
            }
            const formData = new FormData()
            const JSONData = JSON.stringify(data)
            formData.append('data', JSONData)
            for(let i = 0; i < files.length; i++) {

                const fileName = files[i].name;
                if (!fileName) continue;
                // ファイルの拡張子を取得
                const fileExtention = fileName.substring(fileName.lastIndexOf(".") + 1);
                const blob = files[i].slice(0, files[i].size, files[i].type);
                // ファイル名称変更後のファイルオブジェクト
                const renamedFile = new File([blob], files[i]._uuid + "." + fileExtention, {type: files[i].type});

                formData.append('files', renamedFile)
            }

            localStorage.removeItem('formUserToken')
            localStorage.removeItem('formUserInfo')
            const res = await http.post("/form/PostFormData", formData, {'content-type': 'multipart/form-data'}, false, false)
            return res

        },

        async hasBlankInRequired({state}) {
            const formList = state.formState.allFormList.find(x => x.userAttr == state.formState.activeUserAttr)
            const activeForm = state.formState.activeForm
            let blank = false;
            const requiredComponentList = formList.formList.filter(x => x.Required == "true" && x.ComponentType != "Editor")
            
            for(let i = 0; i < requiredComponentList.length; i++) {
                const requiredComponent = requiredComponentList[i]
                if(requiredComponent.items) {
                    for(let j = 0; j < requiredComponent.items.length; j++) {
                        const item = requiredComponent.items[j]
                        if(item.Xtype != "simpleText" && item.Xtype != "spaceField") {
                            if(!(item.Value || item.DateValue || (item.ValueList && item.ValueList.length) || (item.File && item.File.FileName))) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        },
    }
}

