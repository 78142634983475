<template>
    <Toast position="top-left" group="tl" />
    <Toast position="top-center" group="tc" />
    <Toast position="top-right" group="tr" />
    <Toast position="center" group="c" />
    <Toast position="bottom-left" group="bl" />
    <Toast position="bottom-center" group="bc" />
    <Toast position="bottom-right" group="br" />

    <Toast position="bottom-left" group="usefull" />
    <Toast position="bottom-left" group="offline" />

    <div style="display: none;"> {{getUseFullToast}} </div>

    <Sidebar v-model:visible="visibleBottomSidebar" position="bottom"> {{visibleBottomSidebarText}} </Sidebar>
    <ConfirmDialog></ConfirmDialog>
    <ConfirmDialog class="breakConfirmDialog" group="break" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" ></ConfirmDialog>
    <ConfirmDialog class="breakConfirmDialog" group="break2" :breakpoints="{'960px': '50vw', '640px': '75vw'}" :style="{width: '30vw'}" ></ConfirmDialog>
    <ConfirmDialog group="positionDialog"></ConfirmDialog>

    <router-view/>
</template>


<script>
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import Sidebar from 'primevue/sidebar';


export default {
    components: {
        Toast,
        ConfirmDialog,
        ConfirmPopup,
        Sidebar,
    },
    
    computed: {
        getUseFullToast: {
            get: function() {
                const show = this.$store.getters["toastStore/getShow"]
                console.log(show)
                if(show) {
                    const text = this.$store.getters["toastStore/getText"]
                    console.log(text)
                    this.$toast.add({severity: 'success', summary: text, life: this.$constants.successHelperLife, group: "usefull"});
                    this.$store.commit('toastStore/setShow', false)

                }
            },
        },
        visibleBottomSidebar: {
            get: function() {
                return this.$store.getters["sidebarStore/getVisibleBottomSidebar"]
            },
            set: function(value) {
                return this.$store.commit("sidebarStore/setVisibleBottomSidebar", value)
            },
        },

        visibleBottomSidebarText: {
            get: function() {
                return this.$store.getters["sidebarStore/getVisibleBottomSidebarText"]
            },
        },
    },
    data: function() {
        return {
        }
    },
    watch: {
        // getUseFullToast(value, old) {
        //     if(value) {
        //         const text = this.$store.getters["toastStore/getText"]
        //         this.$toast.add({severity:'error', summary: text, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
        //         this.$store.commit('toastStore/setText', "")
        //         this.$store.commit('toastStore/setShow', false)
        //     }
        // },
        // $route: async function(value) {
        // },
        '$i18n.locale': {
            handler (value) {
                this.setHTMLlang(value)
            }
        }
    },
    created() {
        window.addEventListener('online', (e) => {
            this.$toast.removeGroup("offline");
            this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.reconnecting"), group: "offline", life: 1000});
        })
        window.addEventListener('offline', (e) => {
            this.$toast.add({severity:'error', summary: this.$i18n.tc("utils.disconnected"), group: "offline"});
        })
    },
    methods: {
        setHTMLlang (value) {
            document.getElementsByTagName('html')[0].lang = value
        }
    }
}
</script>

<style>
@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0";
html {
    width: 100%;
    height: 100%;
    position: static;
    margin: 0;
    padding: 0;
    border: 0 none;
    overflow-y: auto;
}
body {
    margin: 0;
    padding: 0;
    border: 0 none;    
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    overflow-y: auto;
}
/* html { */
/*     /* overflow: hidden; */ */
/* } */
/* html, body { */
/*     height: 98%; */
/*     width: 100%; */
/*     margin: 0px; */
/*     padding: 0px; */
/* } */
#app {
    /* overflow: scroll; */
    width: 100%;
    position: relative;
    height: auto;
    min-height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
/* .customScrollPanel .p-scrollpanel-wrapper { */
/*     border-right: 9px solid #f4f4f4; */
/* } */
/*  */
/* .customScrollPanel .p-scrollpanel-bar { */
/*     background-color: #1976d2; */
/*     opacity: 1; */
/*     transition: background-color .3s; */
/* } */
/*  */
/* .customScrollPanel .p-scrollpanel-bar:hover { */
/*     background-color: #135ba1; */
/* } */
.customScrollPanel > .p-scrollpanel-wrapper {
    border-right: 9px solid var(--surface-b);
}

.customScrollPanel > .p-scrollpanel-bar {
    background-color: var(--primary-color);
    opacity: 1;
    transition: background-color .2s;

}
.customScrollPanel > .p-scrollpanel-bar {
    background-color: #007ad9 !important;
}
</style>
