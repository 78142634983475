import http from '../mixins/api'

export default {
    namespaced: true,
    state: {
        editingConfirmAlert: false,
    },

    getters: {
        getEditingConfirmAlert(state) {
            return state.editingConfirmAlert
        },
    },

    mutations: {
        setEditingConfirmAlert(state, editing) {
            state.editingConfirmAlert = editing
        },

    },

    actions: {
    },
}
