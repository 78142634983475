import { createStore } from 'vuex'
import inputForm from './inputForm'
import editTemplate from './editTemplate'
import viewPostedForm from './viewPostedForm'
import gridDataStore from './gridDataStore'
import userBranchInfo from './userBranchInfo'
import auth from './auth'
import routeStore from './routeStore'
import trello from './trello'
import activeBoardCard from './activeBoardCard'
import viewPostedFormFromPostedUser from './viewPostedFormFromPostedUser'
import sentMailStore from './sentMailStore'
import settingStore from './settingStore'
import sidebarStore from './sidebarStore'
import urlStore from "./urlStore"
import planedStore from "./planedStore"
import upgradePopup from "./upgradePopup"
import toastStore from "./toastStore"
import detailFormStatus from "./detailFormStatus"
import messageAfterSendingFormStore from "./messageAfterSendingFormStore"
import applicantStore from "./applicantStore"
import formTemplateShowMessageBeforeStore from "./formTemplateShowMessageBeforeStore"
import socketStore from "./socketStore"
import previewFormStore from "./previewFormStore"

// import createPersistedState from 'vuex-persistedstate'

export default createStore({
    state: {
        selectedRowId: null,
        selectedFormType: "",
        showGlobalAdminSpinner: false,
    },
    getters: {
        getSelectRowId(state) {
            return state.selectedRowId
        },
        isShowGlobalAdminSpinner(state) {
            return state.showGlobalAdminSpinner
        },
    },
    mutations: {
        setSelectedRowId(state, {id, type}) {
            if(id) {
                state.selectedRowId = id
                state.selectedFormType = type
            } else {
                state.selectedRowId = null
                state.selectedFormType = ""
            }
        },
        setShowGlobalAdminSpinner(state, showGlobalAdminSpinner) {
            state.showGlobalAdminSpinner = showGlobalAdminSpinner
        },
    },
    actions: {
    },
    modules: {
        editTemplate: editTemplate,
        inputForm: inputForm,
        viewPostedForm: viewPostedForm,
        gridDataStore: gridDataStore,
        userBranchInfo: userBranchInfo,
        auth: auth,
        routeStore: routeStore,
        viewPostedFormFromPostedUser: viewPostedFormFromPostedUser,
        trello: trello,
        activeBoardCard: activeBoardCard,
        sentMailStore: sentMailStore,
        settingStore: settingStore,
        sidebarStore: sidebarStore,
        urlStore: urlStore,
        planedStore: planedStore,
        upgradePopup:upgradePopup,
        toastStore: toastStore,
        detailFormStatus: detailFormStatus,
        messageAfterSendingFormStore: messageAfterSendingFormStore,
        applicantStore: applicantStore,
        formTemplateShowMessageBeforeStore: formTemplateShowMessageBeforeStore,
        socketStore: socketStore,
        previewFormStore: previewFormStore,
    },
    // plugins: [createPersistedState()]
})
