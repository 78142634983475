import http from '../mixins/api'

export default {
    namespaced: true,
    state: {
        form: {
            components: []
        },
        FormStatus: null,
        SendAcceptedMail: null,
        SendRejectedMail: null,
        SendDoneMail: null,
        SendDeletedMail: null,
        FormNote: "",
        FormId: null,
        TemplateId: null,
        DroppedDate: null,
        SendDate: null,
        Stamped: false,
        CanStamp: false,
        FormVersionText: "",
    },
    getters: {
        getFormStatus(state) {
            return state.FormStatus
        },
        getDocumentName(state) {
            return state.form.DocumentName
        },
        getDepartment(state) {
            return state.form.Department
        },
        getDepartmentName(state) {
            return state.form.DepartmentName
        },
        getUserAttr(state) {
            return state.form.UserAttr
        },
        getUserAttrList(state) {
            return state.form.UserAttrList || []
        },
        getAppTypeList(state) {
            return state.form.AppTypeList || []
        },
        getAppType(state) {
            return state.form.AppType || []
        },
        getOpenBegin(state) {
            return state.form.OpenBegin
        },
        getOpenEnd(state) {
            return state.form.OpenEnd
        },
        getControlText(state) {
            return state.form.ControlText
        },
        getDroppedDate(state) {
            return state.DroppedDate
        },
        getSendDate(state) {
            return state.SendDate
        },
        getComponents(state) {
            return state.form.components
        },
        getComponentsData(state) {

        },
        getformData(state) {
            return state.form
        },
        getRecord: (state) => (id) => {
            return state.recordList.find(record => record.ComponentFieldId == id);
        },
        getSendAcceptedMail(state) {
            return state.SendAcceptedMail
        },
        getSendRejectedMail(state) {
            return state.SendRejectedMail
        },
        getSendDoneMail(state) {
            return state.SendDoneMail
        },
        getSendDeletedMail(state) {
            return state.SendDeletedMail
        },
        getFormNote(state) {
            return state.FormNote
        },
        getFormVersionText(state) {
            return state.FormVersionText
        },
        getFormId(state) {
            return state.FormId
        },
        getTemplateId(state) {
            return state.TemplateId
        },
        getFromEmail(state) {
            return state.FromEmail
        },
        getStamped(state) {
            return state.Stamped
        },
        getCanStamp(state) {
            return state.CanStamp
        },
        getFormNoteDisabled(state) {
            return state.FormNoteDisabled
        },
    },
    mutations: {
        setFromEmail(state, FromEmail) {
            state.FromEmail = FromEmail
        },
        setRecordList(state, recordList) {
            state.recordList = recordList
        },
        setDocumentName(state, DocumentName) {
            state.form.DocumentName = DocumentName
        },
        setDepartmentName(state, DepartmentName) {
            state.form.DepartmentName = DepartmentName
        },
        setDepartment(state, Department) {
            state.form.Department = Department
        },
        setUserAttr(state, userAttr) {
            state.form.UserAttr = userAttr
        },
        setAppTypeList(state, appTypeList) {
            state.form.AppTypeList = appTypeList
        },
        setAppType(state, appType) {
            state.form.AppType = appType
        },
        setOpenBegin(state, openBegin) {
            state.form.OpenBegin = openBegin
        },
        setOpenEnd(state, OpenEnd) {
            state.form.OpenEnd = OpenEnd
        },
        setControlText(state, ControlText) {
            state.form.ControlText = ControlText
        },
        setUserAttrList(state, userList) {
            state.form.userAttrList = userList
        },
        setComponentsByItemId(state, Data){
        },
        setComponents(state, components) {
            // state.template.components = components
            state.form.components.splice(0, state.form.components.length, ...components)
        },
        setComponentByItemId(state, Data){
            if(!state.template.components) {
                state.template.components = []
            }
            const component = state.template.components.find(x => x.Id == Data.Id) 
            if(component) {
                const field = component.items.find(x => x.ItemId == Data.ItemId) 
                if(field) {
                    Object.assign(field, Data)
                    // console.log(field) // field.label = Data.label
                    // field.value = Data.value
                    // field.xtype = Data.xtype
                } else {
                    component.items.push(Data)
                }
            } else {
                state.template.components.push({Id: Data.Id, items: [Data]})
            }
        },
        setComponentPropsByItemId(state, Data){
            if(!state.template.components) {
                state.template.components = []
            }
            const component = state.template.components.find(x => x.Id == Data.Id) 
            if(component) {
                component[Data.label] = Data.value
            } else {
                state.template.components.push({
                    Id: Data.Id,
                    [Data.label]: Data.value,
                    items:[]
                })
            }
        },
        addComponent(state, com) {
            state.template.components.push(com)
        },
        setStatus(state, Status) {
            state.FormStatus = Status
        },
        setSendAcceptedMail(state, SendAcceptedMail) {
            state.SendAcceptedMail = SendAcceptedMail
        },
        setSendRejectedMail(state, SendRejectedMail) {
            state.SendRejectedMail = SendRejectedMail
        },
        setSendDoneMail(state, SendDoneMail) {
            state.SendDoneMail = SendDoneMail
        },
        setSendDeletedMail(state, SendDeletedMail) {
            state.SendDeletedMail = SendDeletedMail
        },
        clear(state) {
            state.form = {
                components: []
            }
            state.FormStatus = null
            state.SendAcceptedMail = null
            state.SendRejectedMail = null
            state.SendDoneMail  = null
            state.SendDeletedMail = null
            state.FormNote = null
            state.FormId = null
            state.TemplateId = null
            state.DroppedDate = null
            state.SendDate = null
            state.FormVersionText = ""
        },
        setFormNote(state, value) {
            state.FormNote = value
        },
        setFormVersionText(state, value) {
            state.FormVersionText = value
        },
        setFormId(state, formId) {
            state.FormId = formId
        },
        setTemplateId(state, TemplateId) {
            state.TemplateId = TemplateId
        },
        setDroppedDate(state, DroppedDate) {
            state.DroppedDate = DroppedDate
        },
        setSendDate(state, SendDate) {
            state.SendDate = SendDate
        },
        setStamped(state, Stamped) {
            state.Stamped = Stamped;
        },
        setCanStamp(state, CanStamp) {
            state.CanStamp = CanStamp;
        },
        setFormNoteDisabled(state, FormNoteDisabled) {
            state.FormNoteDisabled = FormNoteDisabled
        }
    },
    actions: {
        async getFormById (context, id) {
            const res = await http.post("/form/GetPostedFormById", {"formId": id})
            if(res.Success) {
                context.commit('setDocumentName', res.Data.DocumentName);
                context.commit('setDepartment', res.Data.Department);
                context.commit('setDepartmentName', res.Data.DepartmentName);
                context.commit('setControlText', res.Data.ControlText);
                context.commit('setUserAttr', res.UserAttrValue);
                context.commit('setAppType', res.Data.AppType);
                context.commit('setAppTypeList', res.AppTypeList);
                context.commit('setStatus', res.FormStatus);
                
                if(res.Data.OpenBegin) {
                    context.commit('setOpenBegin', new Date(res.Data.OpenBegin));
                } else {
                    context.commit('setOpenBegin', null);
                }
                if(res.Data.OpenEnd) {
                    context.commit('setOpenEnd', new Date(res.Data.OpenEnd));
                } else {
                    context.commit('setOpenEnd', null);
                }

                context.commit('setComponents', res.Data.components);
                context.commit('setSendAcceptedMail', res.Data.SendAcceptedMail);
                context.commit('setSendRejectedMail', res.Data.SendRejectedMail);
                context.commit('setSendDoneMail', res.Data.SendDoneMail);
                context.commit('setSendDeletedMail', res.Data.SendDeletedMail);
                context.commit('setFormNote', res.Data.FormNote);
                context.commit('setFormVersionText', res.Data.FormVersionText);
                context.commit('setFormId', res.FormId);
                context.commit('setTemplateId', res.Data._id);
                
                context.commit('setFromEmail', res.FromEmail);
                context.commit('setDroppedDate', res.DroppedDate);
                context.commit('setSendDate', res.SendDate);
                context.commit('setStamped', res.Stamped);
                context.commit('setCanStamp', res.CanStamp);
                context.commit('setFormNoteDisabled', res.FormNoteDisabled);
                
                
                console.log(res)
            }

            return res
        },

        async AcceptForm({context, state}, params) {
            const res = await http.post("/form/AcceptForm", {formId: params.id, detailFormStatusId: params.detailFormStatusId})
            return res;
        },

        async RejectForm({context, state}, params) {
            const res = await http.post("/form/RejectForm", {formId: params.id, detailFormStatusId: params.detailFormStatusId})
            return res;
        },

        async DoneForm({context, state}, params) {
            const res = await http.post("/form/StorageForm", {formId: params.id, detailFormStatusId: params.detailFormStatusId})
            return res;
        },

        async DeleteForm({context, state}, params) {
            const res = await http.post("/form/DeleteForm", {formId: params.id, detailFormStatusId: params.detailFormStatusId})
            return res;
        },

        async checkUpdateFormVersion({commit, state}) {
            const FormId = state.FormId
            const FormVersionText = state.FormVersionText
            const res = await http.post("/form/CheckUpdateFormVersion", {"formId": FormId, "FormVersionText": FormVersionText})

            return res
        },

        async updateFormNote({commit, state}, value) {
            const FormId = state.FormId
            const res = await http.post("/form/UpdateFormNote", {"formId": FormId, "FormNote": value})
            if(res.Success) {
                commit("setFormNote", value)
                commit("setFormVersionText", res.FormVersionText)
            }
        },
        async stampForm({commit, state}) {
            const FormId = state.FormId
            const res = await http.post("/form/StampFormByUserName", {_id: FormId, Type: "Form"})
            if(res.Success) {
                commit('setStamped', true);
            }
            return res;
        },
        async removeStampForm({commit, state}) {
            const FormId = state.FormId
            const res = await http.post("/form/RemoveStampForm", {_id: FormId, Type: "Form"})
            if(res.Success) {
                commit('setStamped', false);
            }
            return res;
        },
        async moveDetailStatusForm({commit, state}, params) {
            const FormId = state.FormId
            const DetailFormStatusId = params.DetailFormStatusId
            const res = await http.post("/form/MoveDetailStatusForm", {_id: FormId, DetailFormStatusId: DetailFormStatusId})
            if(res.Success) {
            }
            return res;
        },
        async  moveDetailStatusFormBulk({commit, state}, params) {
            const FormId = state.FormId
            const isSelectedAll = params.isSelectedAll
            const selectedFormId = params.selectedFormId
            const DetailFormStatusId = params.DetailFormStatusId
            const DepartmentId = params.DepartmentId
            const formStatus = params.formStatus
            const res = await http.post("/form/MoveDetailStatusFormBulk", {selectedFormId: selectedFormId, DetailFormStatusId: DetailFormStatusId, isSelectedAll: isSelectedAll, formStatus: formStatus, DepartmentId: DepartmentId})
            return res;
        },
    }
}


