import http from '@/mixins/api'

export default {
    namespaced: true,
    state: {
        AllCardList : [],
        _id: "",
        Title: "",
        Content: "",
        CoverImageUrl: "",
        ListContent: "",
        CompanyId: "",
        DepartmentId: "",
        BoardType: "",
        UpdatedDate: null,
        AllBoards: [],
        IsPinned: false,
        AttachmentList: [],
        AllBoardTagList: [],
    },
    getters: {
        getCardId(state) {
            return state._id
        },
        getTitle(state) {
            return state.Title
        },
        getContent(state) {
            return state.Content
        },
        getCoverImageUrl(state) {
            return state.CoverImageUrl
        },
        getListContent(state) {
            return state.ListContent
        },
        getCompanyId(state) {
            return state.CompanyId
        },
        getDepartmentId(state) {
            return state.DepartmentId
        },
        getBoardType(state) {
            return state.BoardType
        },
        getAllCards(state) {
            return state.AllCardList
        },
        getAllBoards(state) {
            return state.AllBoards
        },
        getAllBoardDataList(state) {
            const AllBoards = state.AllBoards
            const BoardDataList = AllBoards.map(x => { 
                return {
                    _id: x._id,
                    BoardType: x.BoardType,
                    Label: x.Label,
                    CompanyId: x.CompanyId,
                    DepartmentId: x.DepartmentId,
                    UserId: x.UserId,
                }
            })
            
            return BoardDataList
        },
        getAllBoardList(state) {
            const AllBoards = state.AllBoards
            const BoardLists = []
            for (let i = 0; i < AllBoards.length; i++) {
                const Lists = AllBoards[i].Lists
                for (let j = 0; j < Lists.length; j++) {
                    BoardLists.push({
                        _id: Lists[j]._id,
                        BoardId: Lists[j].BoardId,
                        title: Lists[j].Title,
                        content: Lists[j].Content,
                        label: Lists[j].Title,
                    })
                }
            }
            
            return BoardLists
        },
        getUpdatedDate(state) {
            return state.UpdatedDate
        },
        getListUpdatedDate(state) {
            return state.ListUpdatedDate
        },
        getPinned(state) {
            return state.IsPinned
        },
        getAttachmentList(state) {
            return state.AttachmentList
        },
        getUserId(state) {
            return state.UserId;
        },
        getAllBoardTagList(state) {
            return state.AllBoardTagList
        }
    },
    mutations: {
        clear(state) {
            state._id = ""
            state.Title = ""
            state.Content = ""
            state.CoverImageUrl = ""
            state.ListContent = ""
            state.CompanyId = ""
            state.DepartmentId = ""
            state.BoardType = ""
            state.UpdatedDate = null
            state.ListUpdatedDate = null
            state.IsPinned = null
            
        },
        setId(state, _id) {
            state._id = _id
        },
        setTitle(state, title) {
            state.Title = title
        },
        setContent(state, content) {
            state.Content = content
        },
        setCoverImageUrl(state, url) {
            state.CoverImageUrl = url
        },
        setListContent(state, listContent) {
            state.ListContent = listContent
        },
        setCompanyId(state, CompanyId) {
            state.CompanyId = CompanyId
        },
        setDepartmentId(state, DepartmentId) {
            state.DepartmentId = DepartmentId
        },
        setBoardType(state, BoardType) {
            state.BoardType = BoardType
        },
        setCardList(state, CardList) {
            state.AllCardList = CardList
        },
        setUpdatedDate(state, UpdatedDate) {
            state.UpdatedDate = UpdatedDate
        },
        setListUpdatedDate(state, UpdatedDate) {
            state.ListUpdatedDate = UpdatedDate
        },
        setIsPinned(state, IsPinned) {
            state.IsPinned = IsPinned
        },
        setAttachmentList(state, AttachmentList) {
            state.AttachmentList = AttachmentList
        },
        setAllBoards(state, AllBoards) {
            state.AllBoards = AllBoards
        },
        setBelongToTitle(state, BelongToTitle) {
            state.BelongToTitle = BelongToTitle
        },
        setListName(state, ListName) {
            state.ListName = ListName
        },
        setAllBoardTagList(state, AllBoardTagList) {
            state.AllBoardTagList = AllBoardTagList
        },
    },
    actions: {
        async getCardInfoById({commit}, _id) {
            const res = await http.post("/board/GetCardInfoById", {_id: _id})
            if(res.Success) {
                commit("setId", res.Card._id)
                commit("setTitle", res.Card.Title)
                commit("setContent", res.Card.Content)
                commit("setCoverImageUrl", res.Card.CoverImageUrl)
                commit("setCompanyId", res.Card.CompanyId)
                commit("setCompanyName", res.Card.CompanyId)
                commit("setDepartmentId", res.Card.DepartmentId)
                commit("setBoardType", res.Card.BoardType)
                commit("setUpdatedDate", res.Card.UpdatedDate)
                commit("setIsPinned", res.Card.IsPinned)
                commit("setAttachmentList", res.AttachmentList)
                commit("setBelongToTitle", res.BelongToTitle)
                commit("setListName", res.ListName)
            }

            return res;
        },

        async getCardInfoByIdAndUpdateBack({commit, state}, {_id, BoardId}) {
            const res = await http.post("/board/GetCardInfoById", {_id: _id})
            const Card = res.Card
            const Board = state.AllBoards.find(x => x._id == BoardId)
            const List = Board.Lists.find(x => x._id == Card.BoardListId)
            const index = List.Cards.findIndex(x => x._id == Card._id)
            if(index != -1) {
                List.Cards.splice(index, 1, Card)
            }

            return res;
        },

        async getListContentInfoById({commit}, listId) {
            const res = await http.post("/user/GetListContentInfoById", {BoardListId: listId})
            if(res.Success) {
                commit("setListContent", res.List.Content)
                commit("setListUpdatedDate", res.List.UpdatedDate)
            }

            return res;
        },

        async setListContentInfoById({commit, state}, params) {
            const ListContent = state.ListContent
            const res = await http.post("/user/SetListContentInfoById", {BoardListId: params.listId, ListContent})

            return res;
        },

        async getAllBoards({commit}) {
            const res = await http.post("/user/GetAllBoards")
            commit("setAllBoards", res.AllBoards)
        },

        async getAllBoardTagList({commit}) {
            const res = await http.post("/board/GetAllBoardTagList")
            commit('setAllBoardTagList', res.AllTagList)
        },

        async getBoardTagList({commit, state}, boardId) {
            const res = await http.post("/board/GetAllBoardTagList", {BoardId: boardId})
            const BoardTagList = res.AllTagList
            const AllBoardTagList = state.AllBoardTagList
            BoardTagList.push(...AllBoardTagList.filter(x => x.BoardId != boardId))
            commit('setAllBoardTagList', BoardTagList)
        },

        async getAllCards({commit}) {
            const res = await http.post("/user/GetAllBoardNotes")
            commit("setCardList", res.BoardNoteList)
            // state.AllCardList = res.BoardNoteList
        },

        async getBoardListCards({commit, state}, params) {
            const BoardId = params.BoardId
            const BoardListId = params.BoardListId
            const res = await http.post("/user/GetAllBoardListCard", {BoardListId: BoardListId})
            if(res.Success) {
                const Board = state.AllBoards.find(x => x._id == BoardId)
                const List = Board.Lists.find(x => x._id == BoardListId)
                if(List)
                    List.Cards = res.Cards
            }
            commit("setCardList", res.BoardNoteList)
            // state.AllCardList = res.BoardNoteList
        },


        async createNewCard({commit}, params) {
            const BoardListId = params.BoardListId
            const title = params.Title
            const res = await http.post("/board/CreateNewBoardCard", {BoardListId: BoardListId, Title: title})

            return res
        },

        async updateCardContent({commit}, params) {
            const BoardListId = params.BoardListId
            const Content = params.Content
            const CardId = params.CardId
            const res = await http.post("/board/UpdateBoardCard", {BoardListId: BoardListId, CardId: CardId, Content: Content})

            return res
        },

        async updateCardTitle({commit, state}, params={}) {
            const data = {
                _id: params._id ? params._id : state._id,
                Title: params.Title ? params.Title : state.Title,
                CompanyId: state.CompanyId,
                DepartmentId: state.DepartmentId,
                BoardType: state.BoardType,
            }
            const res = await http.post("/board/UpdateCardTitle", data)
            return res
        },

        async addCardCoverImage({commit, state}, form) {
            const data = {
                _id: state._id,
                form: form,
            }
            form.append("_id", state._id)
            const res = await http.post("/board/AddCardCoverImage", form, {"Content-Type": "multipart/form-data"})
            return res
        },

        async upsertNewCard({commit, state}) {
            const params = {
                _id: state._id,
                Content: state.Content,
                CompanyId: state.CompanyId,
                DepartmentId: state.DepartmentId,
                BoardType: state.BoardType,
            }
            const res = await http.post("/user/UpsertNewBoardCard", params)
            return res
        },

        async trashBoardCard({commit}, _id) {
            const res = await http.post("/user/TrashBoardCard", {_id: _id})

            return res
        },

        async insertedCard({commit, state}, params) {
            const Card = params.Card 
            const newIndex = params.newIndex
            const oldIndex = params.oldIndex
            const OldBoardListId = params.OldBoardListId
            const NewBoardListId = params.NewBoardListId
            const Direction = params.Direction
            const res = await http.post("/user/InsertedCard", {Card: Card, newIndex: newIndex, oldIndex: oldIndex, OldBoardListId: OldBoardListId, NewBoardListId: NewBoardListId, Direction: Direction})

            return res
        },

        async setBoardListTitle({}, params) {
            const value = params.value
            const BoardListId = params.BoardListId
            const res = await http.post("/user/SetBoardListTitle", {BoardListId: BoardListId, value: value})

            return res

        },

        async createNewBoardList({}, params) {
            const BoardId = params.BoardId
            const res = await http.post("/user/CreateNewBoardList", {BoardId: BoardId})

            return res
        },

        async getBoardLists({state}, params) {
            const BoardId = params.BoardId
            const res = await http.post("/user/GetBoardLists", {BoardId: BoardId})
            if(res.Success) {
                const Board = state.AllBoards.find(x => x._id == BoardId)
                Board.Lists = res.Lists
            }

            return res
        },

        async deleteBoardList({}, params) {
            const BoardId = params.BoardId
            const BoardListId = params.BoardListId
            
            const res = await http.post("/user/DeleteBoardList", {BoardId: BoardId, BoardListId: BoardListId})

            return res
        },

        async setPinnedCard({}, params) {
            const BoardCardId = params.BoardCardId
            const IsPinned = params.IsPinned 
            const res = await http.post("/user/SetPinnedBoardCard", {BoardCardId, IsPinned})

            return res
        },

        async deleteAttachment({}, params) {
            const res = await http.post("/board/DeleteAttachment", {CardId: params.BoardCardId, AttachmentId: params.AttachmentId})

            return res
        },

        async removeCoverAttachment({}, params) {
            const res = await http.post("/board/RemoveCoverAttachment", {CardId: params.BoardCardId, AttachmentId: params.AttachmentId})

            return res
        },

        async makeCoverAttachment({}, params) {
            const res = await http.post("/board/MakeCoverAttachment", {CardId: params.BoardCardId, AttachmentId: params.AttachmentId})

            return res
        },

        async createNewCardTag({}, tagInfo) {
            const data = {
                boardId: tagInfo.BoardId,
                tagLabel: tagInfo.Label,
                tagColor: tagInfo.Color,
            }

            const res = await http.post("/board/updateTag", data)
            return res
        },

        async moveCardInPanel({}, {BoardListId, No, CardId}) {
            const data = {
                boardListId: BoardListId,
                cardId: CardId,
                no: No,
            }

            const res = await http.post("/board/MoveCard", data)
            return res
        }
    }
}


