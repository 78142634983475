export default {
    namespaced: true,
    state: {
        text: "",
        show: false,
    },

    getters: {
        getText(state) {
            return state.text
        },
        getShow(state) {
            return state.show
            const show = state.show
            state.show = false
            return show
        },
    },

    mutations: {
        setText(state, text) {
            state.text = text
        },
        setShow(state, show) {
            state.show = show
        },
    },

    actions: {
    },
}

