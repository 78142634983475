import http from '../mixins/api'
import authApi from '../mixins/authApi'
import { uuid } from "vue-uuid";
// import store from '@/store'
import i18n from '@/lang'
import axios from 'axios'
import constants from '@/mixins/constants'

export default {
    namespaced: true,
    state: {
        // jwt: null,
        userInfo: {},
        authPointHint: {
            email: "",
            password: "",
        },
        checkingAuth: false,
        keyCount: 0,
    },

    getters: {
        getUserInfo(state) {
            if(localStorage.userInfo) {
                return JSON.parse(localStorage.userInfo)
            }

            return null;
        },

        getUserInfoInState(state) {
            if (state.userInfo && state.userInfo.Email) {
                return state.userInfo
            }
            if(localStorage.userInfo) {
                return JSON.parse(localStorage.userInfo)
            }

            return {};
        },

        getKeyCount(state) {
            return state.keyCount
        },

        getJwt(state) {
            return localStorage.jwt
        },

        getUserLanguage(state) {
            return i18n.global.locale;
        },

        getAuthPointHintAddParams(state) {
            const hint = state.authPointHint

            return hint;
        },
    },

    mutations: {
        logout(state) {
            // state.jwt = null
            // state.userInfo = null
            localStorage.removeItem("jwt") 
            localStorage.removeItem("userInfo") 
        },

        login(state, info) {
            // state.jwt = info.token
            // state.userInfo = info.userInfo
            if(info.token) {
                localStorage.jwt = info.token
            }
            if(info.userInfo) {
                localStorage.userInfo = JSON.stringify(info.userInfo)
            }

            this.commit("auth/setTABUUID")
        },

        setTABUUID(state) {
            const rand = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });

            localStorage.setItem('TABUUID', rand)
        },

        setToken(state, token) {
            localStorage.jwt = token
        },

        setUserInfoLanguage(state, language) {
            const userInfo = this.getters["auth/getUserInfoInState"]
            if(userInfo) {
                userInfo.language = language;
                this.commit("auth/setUserInfo", userInfo)
                this.commit("auth/setIncKeyCount", 1)
            }
        },

        setUserInfoTimezone(state, timezone) {
            const userInfo = this.getters["auth/getUserInfoInState"]
            if(userInfo && userInfo.timezone != timezone) {
                userInfo.timezone = timezone;
                this.commit("auth/setUserInfo", userInfo)
                this.commit("auth/setIncKeyCount", 1)
            }
        },

        setUserInfoDateformat(state, dateformat) {
            const userInfo = this.getters["auth/getUserInfoInState"]
            if(userInfo && userInfo.dateformat != dateformat) {
                userInfo.dateformat = dateformat;
                this.commit("auth/setUserInfo", userInfo)
                this.commit("auth/setIncKeyCount", 1)
            }
        },

        setUserInfoEmail(state, email) {
            const userInfo = this.getters["auth/getUserInfoInState"]
            if (userInfo.email != email) {
                userInfo.Email = email
                this.commit("auth/setUserInfo", userInfo)
            }
        },

        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
            localStorage.userInfo = JSON.stringify(userInfo)
        },

        setKeyCount(state, keyCount) {
            state.keyCount = keyCount
        },

        setIncKeyCount(state, keyCount) {
            state.keyCount = state.keyCount + 1
        },

        setAuthPointHintAddParams(state, params) {
            if(params.email) {
                state.authPointHint.email = params.email
            }
            if(params.password) {
                state.authPointHint.password = params.password
            }
        },

        clearAuthPointHintAddParams(state) {
            state.authPointHint.email = ""
            state.authPointHint.password = ""
        }
    },

    actions: {

        async getUserInfo({commit}) {
            const res = await http.post("/user/GetUserInfo")
            if(res.Success) {
                commit("setUserInfo", res.UserInfo)
            }

            return res.Success
        },

        
        async loginByAuthCode({commit, state}, params) {
            const res = await http.post('/user/LoginWorkUserByAuthCode', params,  {withCredentials: true} , false)
            // const res = await authApi.post('/user/LoginUserByAuthorizationCode', params, null, false)
            commit("logout")
            if(res.Success) {
                commit("login", {
                    token: res.token,
                    // userInfo: res.userInfo,
                })
            }
            // store.commit("settingStore/setLanguageByValue", userInfo.language)
            return res
        },


        async checkAuth({commit, getters, state}) {
            const res = await http.beforeAuthPost({}, false)
            return res.Success
            // if(!state.checkingAuth) {
            //     state.checkingAuth = true
            //     
            //     const res = await http.beforeAuthPost({}, false)
            //     state.checkingAuth = false
            //     return res.Success ? 1 : 0
            // }
            // return 2
        },

        async login({commit, state}, params) { // Email, Password
            const res = await http.post('/user/LoginWorkUser', params, null, false)
            if(res.Success) {
                commit("login", {
                    token: res.token,
                    userInfo: res.userInfo,
                })
            }
            return res;
        },

        async logout({commit, state}, params) {
            const res = await http.post('/auth/Logout', params, null, false, false)
            if(res.Success) {
                commit("logout")
            }

            return res.Success
        },

        setLanguageInUserInfo({context, dispatch, commit, getters}, value) {
            let userInfo = getters.getUserInfo

            userInfo.language = value;

            commit('setUserInfo', userInfo)
        },

        async authPoint(context, options={}) {
            const res = await http.post("/auth/AuthPoint", {}, null, false, false)
            const urlObject = new URL(res.url)
            const hint = context.getters["getAuthPointHintAddParams"]
            const params = new URLSearchParams(urlObject.search.substring(1));
            const state = params.get("state")
            const nonce = params.get("nonce")
            localStorage.state = state
            localStorage.nonce = nonce
            // alert(JSON.stringify(hint))
            if(hint) {
                if(hint.email) {
                    params.append("email_hint", hint.email)
                }
                if(hint.password) {
                    params.append("password_hint", hint.password)
                }
                if(hint.email && hint.password) {
                    params.append("auto_login", "true")
                }
                context.commit("clearAuthPointHintAddParams")
            }
            
            localStorage.code_verifier = res.code_verifier
            const redirect_url = options.redirect_url
            if(redirect_url) {
                localStorage.redirect_url = redirect_url;
            }
            if(options.create_account) {
                params.append("create_account", "true")
            }

            const url = urlObject.origin + urlObject.pathname + "?" + params.toString();

            window.location.href = url
        },
    },
}
