import http from '@/mixins/api'

export default {
    namespaced: true,
    state: {
        _id: "",
        Title: "",
        OriginalTitle: "",
        Content: "",
        CoverImageUrl: "",
        CoverFileNameExt: "",
        CoverFileName: "",
        UpdatedDate: null,
        IsPinned: false,
        AttachmentList: [],
        No: "",
        BelongToTitle: "",
        ListName: "",
        TagIdList: [],
        CardVersionText: "",
    },
    getters: {
        getInitData(state) {
            return {
                _id: "",
                Title: "",
                OriginalTitle: "",
                Content: "",
                CoverImageUrl: "",
                CoverFileNameExt: "",
                CoverFileName: "",
                UpdatedDate: null,
                IsPinned: false,
                AttachmentList: [],
                No: "",
                BelongToTitle: "",
                ListName: "",
                TagIdList: [],
            }
        },
        getId(state) {
            return state._id
        },
        getTitle(state) {
            return state.Title
        },
        getOriginalTitle(state) {
            return state.OriginalTitle
        },
        getContent(state) {
            return state.Content
        },
        getCoverImageUrl(state) {
            return state.CoverImageUrl
        },
        getCoverFileNameExt(state) {
            return state.CoverFileNameExt
        },
        getCoverFileName(state) {
            return state.CoverFileName
        },
        getUpdatedDate(state) {
            return state.UpdatedDate
        },
        getPinned(state) {
            return state.IsPinned
        },
        getAttachmentList(state) {
            return state.AttachmentList
        },
        getNo(state) {
            return state.No
        },
        getBelongToTitle(state) { 
            return state.BelongToTitle
        },
        getListName(state) { 
            return state.ListName
        },
        getTagIdList(state) { 
            return state.TagIdList
        },
        getCardVersionText(state) { 
            return state.CardVersionText
        },
    },
    mutations: {
        clear(state) {
            state._id = ""
            state.Title = ""
            state.OriginalTitle = ""
            state.Content = ""
            state.CoverImageUrl = ""
            state.CoverFileNameExt = ""
            state.CoverFileName = ""
            state.UpdatedDate = null
            state.IsPinned = false
            state.AttachmentList = []
            state.No = ""
            state.BelongToTitle = ""
            state.ListName = ""
            state.TagIdList = []
            state.CardVersionText = ""
        },
        setId(state, _id) {
            state._id = _id
        },
        setTitle(state, title) {
            state.Title = title
        },
        setOriginalTitle(state, OriginalTitle) {
            state.OriginalTitle = OriginalTitle
        },
        setContent(state, content) {
            state.Content = content
        },
        setCoverImageUrl(state, url) {
            state.CoverImageUrl = url
        },
        setCoverFileNameExt(state, CoverFileNameExt) {
            state.CoverFileNameExt = CoverFileNameExt
        },
        setCoverFileName(state, CoverFileName) {
            state.CoverFileName = CoverFileName
        },
        setUpdatedDate(state, UpdatedDate) {
            state.UpdatedDate = UpdatedDate
        },
        setIsPinned(state, IsPinned) {
            state.IsPinned = IsPinned
        },
        setAttachmentList(state, AttachmentList) {
            state.AttachmentList = AttachmentList
        },
        setNo(state, No) {
            state.No = No
        },
        setBelongToTitle(state, BelongToTitle) {
            state.BelongToTitle = BelongToTitle
        },
        setListName(state, ListName) {
            state.ListName = ListName
        },
        setTagIdList(state, TagIdList) {
            state.TagIdList = TagIdList
        },
        setCardVersionText(state, CardVersionText) { 
            state.CardVersionText = CardVersionText
        },
    },
    actions: {
        async getCardInfoById({commit}, _id) {
            const res = await http.post("/board/GetCardInfoById", {_id: _id})
            // if(res.Success) {
            //     commit("setId", _id)
            //     commit("setTitle", res.Card.Title)
            //     commit("setOriginalTitle", res.Card.Title)
            //     commit("setContent", res.Card.Content)
            //     commit("setCoverImageUrl", res.Card.CoverImageUrl)
            //     commit("setCoverFileNameExt", res.Card.CoverFileNameExt)
            //     commit("setCoverFileName", res.Card.CoverFileName)
            //     commit("setUpdatedDate", res.Card.UpdatedDate)
            //     commit("setIsPinned", res.Card.IsPinned)
            //     commit("setAttachmentList", res.AttachmentList)
            //     commit("setNo", res.Card.No)
            //     commit("setBelongToTitle", res.BelongToTitle)
            //     commit("setListName", res.ListName)
            //     commit("setTagIdList", res.Card.TagIdList ? res.Card.TagIdList : [])
            //     
            //
            // }

            return res;
        },

        async setCardInfo({commit}, res) {
            commit("setId", res.Card._id)
            commit("setTitle", res.Card.Title)
            commit("setOriginalTitle", res.Card.Title)
            commit("setContent", res.Card.Content)
            commit("setCoverImageUrl", res.Card.CoverImageUrl)
            commit("setCoverFileNameExt", res.Card.CoverFileNameExt)
            commit("setCoverFileName", res.Card.CoverFileName)
            commit("setUpdatedDate", res.Card.UpdatedDate)
            commit("setIsPinned", res.Card.IsPinned)
            commit("setAttachmentList", res.AttachmentList)
            commit("setNo", res.Card.No)
            commit("setBelongToTitle", res.BelongToTitle)
            commit("setListName", res.ListName)
            commit("setTagIdList", res.Card.TagIdList ? res.Card.TagIdList : [])
            commit("setCardVersionText", res.Card.CardVersionText ? res.Card.CardVersionText : "")
            
        },


        async addCardCoverImage({commit, state}, {form, _id}) {
            form.append("_id", _id ? _id : state._id)
            const res = await http.post("/board/AddCardCoverImage", form, {"Content-Type": "multipart/form-data"})
            return res
        },

        async trashBoardCard({commit}, _id) {
            const res = await http.post("/user/TrashBoardCard", {_id: _id})

            return res
        },

        async trashBoardCardForever({commit}, _id) {
            const res = await http.post("/board/RemoveCard", {cardId: _id})

            return res
        },


        async setPinnedCard({}, params) {
            const BoardCardId = params.BoardCardId
            const IsPinned = params.IsPinned 
            const res = await http.post("/user/SetPinnedBoardCard", {BoardCardId, IsPinned})

            return res
        },

        async commentAttachment({state}, params) {
            const res = await http.post("/board/CommentAttachment", {CardId: params.BoardCardId, AttachmentId: params.AttachmentId, Comment: params.Comment})
            if(res.Success) {
                const attachment = state.AttachmentList.find(x => x._id == params.AttachmentId)
                attachment.CardAttachmentVersionText = res.CardAttachmentVersionText
            }

            return res
        },

        async changeFileNameAttachment({}, params) {
            const res = await http.post("/board/ChangeFileNameAttachment", {CardId: params.BoardCardId, AttachmentId: params.AttachmentId, FileName: params.FileName})

            return res
        },

        async deleteAttachment({}, params) {
            const res = await http.post("/board/DeleteAttachment", {CardId: params.BoardCardId, AttachmentId: params.AttachmentId})

            return res
        },

        async removeCoverAttachment({}, params) {
            const res = await http.post("/board/RemoveCoverAttachment", {CardId: params.BoardCardId, AttachmentId: params.AttachmentId})

            return res
        },

        async makeCoverAttachment({}, params) {
            const res = await http.post("/board/MakeCoverAttachment", {CardId: params.BoardCardId, AttachmentId: params.AttachmentId})

            return res
        },

        async upsertNewCard({commit, state}) {
            const params = {
                _id: state._id,
                Content: state.Content,
            }
            const res = await http.post("/user/UpsertNewBoardCard", params)
            if(res.Success) {
                commit("setCardVersionText", res.CardVersionText)
            }
            return res
        },

        async updateCardTitle({commit, state}, params={}) {
            const data = {
                _id: params._id ? params._id : state._id,
                Title: params.Title ? params.Title : state.Title,
            }
            const res = await http.post("/board/UpdateCardTitle", data)
            if(res.Success) {
                commit("setCardVersionText", res.CardVersionText)
            }
            return res
        },

        async updateCardTag({}, tagInfo) {
            const data = {
                boardId: tagInfo.BoardId,
                tagId: tagInfo._id,
                tagLabel: tagInfo.Label,
                tagColor: tagInfo.Color,
            }

            const res = await http.post("/board/updateTag", data)
            return res
        },

        async selectTagInCard({}, params) {
            const data = {
                tagId: params.tagId,
                cardId: params.cardId,
            }
            const res = await http.post("/board/updateTagInCard", data)
            return res
        },

        async loadAttachments({commit, state}, params={}) {
            const data = {
                cardId: params.cardId ? params.cardId : state._id,
            }
            const res = await http.post("/board/GetAttachmentsInCard", data)

            commit("setCoverImageUrl", res.CoverImageUrl)
            commit("setCoverFileNameExt", res.CoverFileNameExt)
            commit("setCoverFileName", res.CoverFileName)
            commit("setAttachmentList", res.AttachmentList)

            return res
        },

        async checkCardVersion({commit, state}, params={}) {
            const data = {
                cardId: params.cardId ? params.cardId : state._id,
                CardVersionText: state.CardVersionText
            }
            const res = await http.post("/board/CheckCardVersion", data)

            return res
        },

        async checkCardAttachmentVersion({commit, state}, params={}) {
            const data = {
                cardId: params.cardId ? params.cardId : state._id,
                AttachmentId: params.AttachmentId,
                CardAttachmentVersionText: params.CardAttachmentVersionText
            }
            const res = await http.post("/board/CheckCardAttachmentVersion", data)

            return res
        },
    }
}



