import http from '../mixins/api'
import { uuid } from "vue-uuid";
import router from '@/router'
import constants from '@/mixins/constants'


export default {
    namespaced: true,
    state: {
        draftGridDataList: [], // all
        gridDataList: [], // all
        activeStatus: {
            Type: "Template",
            Status: 0,
        },
        showSpinner: false,
        selectedGridDataList: [],
        editableForm: false,
        viewDataTable: true,
        selectedData: [],
        gridDataTotalRecord: {
        },
        gridFilterData: {
        },
        gridSortData: {
        },
        gridPageData: {
        },
        isFilteringGridData: {},
        loading: false,
    },
    getters: {
        getShowSpinner(state) {
            return state.showSpinner
        },
        getSelectedGridDataList(state) {
            return state.selectedGridDataList
        },
        getDraftGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Template" && x.Status == 0)
            if(Data) {
                Data.GridData.forEach(d => {
                    if(d.OpenBegin)
                        d.OpenBegin = new Date(d.OpenBegin)
                    if(d.OpenEnd)
                        d.OpenEnd = new Date(d.OpenEnd)
                })
                return Data.GridData
            }

            return []
        },

        getPublicGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Template" && x.Status == 1)
            if(Data) {
                Data.GridData.forEach(d => {
                    if(d.OpenBegin)
                        d.OpenBegin = new Date(d.OpenBegin)
                    if(d.OpenEnd)
                        d.OpenEnd = new Date(d.OpenEnd)
                })
                return Data.GridData
            }

            return []
        },
        getGarbageGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Template" && x.Status == 2)
            if(Data) {
                Data.GridData.forEach(d => {
                    if(d.OpenBegin)
                        d.OpenBegin = new Date(d.OpenBegin)
                    if(d.OpenEnd)
                        d.OpenEnd = new Date(d.OpenEnd)
                })
                return Data.GridData
            }

            return []
        },
        getReceivedGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Form" && x.Status == 1)
            if(Data) {
                Data.GridData.forEach(d => d.SendDate = new Date(d.SendDate))
                return Data.GridData
            }
        },
        getAcceptedGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Form" && x.Status == 2)
            if(Data) {
                Data.GridData.forEach(d => d.SendDate = new Date(d.SendDate))
                return Data.GridData
            }
        },
        getRejectedGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Form" && x.Status == 3)
            if(Data) {
                Data.GridData.forEach(d => d.SendDate = new Date(d.SendDate))
                return Data.GridData
            }
        },
        getStorageGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Form" && x.Status == 4)
            if(Data) {
                Data.GridData.forEach(d => d.SendDate = new Date(d.SendDate))
                return Data.GridData
            }
        },
        getDeletedGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Form" && x.Status == 5)
            if(Data) {
                Data.GridData.forEach(d => d.SendDate = new Date(d.SendDate))
                return Data.GridData
            }
        },
        getDroppedGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Form" && x.Status == 6)
            if(Data) {
                Data.GridData.forEach(d => d.SendDate = new Date(d.SendDate))
                return Data.GridData
            }
        },
        getSentGridDataList(state) {
            const Data = state.gridDataList.find(x => x.Type == "Sent" && x.Status == 1)
            if(Data) {
                Data.GridData.forEach(d => d.SendDate = new Date(d.SendDate))
                return Data.GridData
            }
        },
        isEditableForm(state) {
            return state.editableForm
        },
        getActiveStatus(state) {
            return state.activeStatus
        },
        getSelectedData(state) {
            return state.selectedData
        },
        getViewDataTable(state) {
            return state.viewDataTable
        },
        getGridDataTotalRecord(state) {
            return state.gridDataTotalRecord
        },
        getGridFilterData(state) {
            return state.gridFilterData
        },
        getGridSortData(state) {
            return state.gridSortData
        },
        getGridPageData(state) {
            return state.gridPageData
        },
        getGridPageDataByType: (state) => (Type) => {
            return state.gridPageData[Type] || {}
        },
        getGridSortDataByType: (state) => (Type) => {
            return state.gridSortData[Type] || {}
        },
        getGridFilterDataByType: (state) => (Type) => {
            return state.gridFilterData[Type] || {}
        },
        getIsFiltering(state) {
            return state.isFilteringGridData
        },
        getLoading(state) {
            return state.loading
        },
        getActiveGridData(state) {
            const activeStatus = state.activeStatus
            const Data = state.gridDataList.find(x => x.Type == activeStatus.Type && x.Status == activeStatus.Status)
            if(Data) {
                const GridData = Data.GridData
                return GridData
            }
            return []
        }
    },
    mutations: {
        setShowSpinner(state, show) {
            state.showSpinner = show
        },
        setSelectedGridDataList(state, list) {
            state.selectedGridDataList.splice(0, state.selectedGridDataList.length, ...list)
        },
        setDraftGridDataList(state, _id) {
            state.info._id = _id
        },
        setDataInDataList(state, data) {
            const _id = data._id
            const activeStatus = state.activeStatus
            const Data = state.gridDataList.find(x => x.Type == activeStatus.Type && x.Status == activeStatus.Status)
            if(Data) {
                const GridData = Data.GridData
                const targetIndex = GridData.findIndex(x => x._id == _id)
                if(targetIndex != -1) {
                    GridData[targetIndex][data.prop] = data.value
                }
            }
        },
        setEditableForm(state, value) {
            state.editableForm = value
        },
        setSelectedData(state, formId) {
            const activeStatus = state.activeStatus
            const Data = state.gridDataList.find(x => x.Type == activeStatus.Type && x.Status == activeStatus.Status)
            if(Data) {
                const GridData = Data.GridData
                const selectedData = GridData.find(x => x._id == formId)
                state.selectedData = selectedData
            }
        },
        setViewDataTable(state, viewDataTable) {
            state.viewDataTable = viewDataTable
        },
        setGridDataTotalRecord(state, params) {
            const gridDataTotalRecord = params.gridDataTotalRecord
            const Type = params.Type
            const Status = params.Status
            if(state.gridDataTotalRecord[Type]) {
                state.gridDataTotalRecord[Type][Status] = gridDataTotalRecord
            } else {
                state.gridDataTotalRecord[Type] = {[Status]: gridDataTotalRecord}
            }
        },
        setGridFilterData(state, params) {
            const gridFilterData = params.gridFilterData
            const Type = params.Type
            const Status = params.Status
            if(state.gridFilterData[Type]) {
                state.gridFilterData[Type][Status] = gridFilterData
            } else {
                state.gridFilterData[Type] = {[Status]: gridFilterData}
            }
        },
        clearGridSortData(state, params) {
            const Type = params.Type
            const Status = params.Status
            if (state.gridSortData[Type]) {
                state.gridSortData[Type][Status] = {}
            }
        },
        setGridSortData(state, params) {
            const gridSortOrder = params.gridSortOrder
            const gridSortField = params.gridSortField
            const Type = params.Type
            const Status = params.Status
            if(state.gridSortData[Type] && state.gridSortData[Type][Status]) {
                state.gridSortData[Type][Status][gridSortField] = gridSortOrder
            } else {
                state.gridSortData[Type] = {[Status]: {[gridSortField]: gridSortOrder}}
            }
        },
        setGridPageData(state, params) {
            const gridPageData = params.gridPageData
            const gridRowsInPageData = params.gridRowsInPageData
            const Type = params.Type
            const Status = params.Status
            if(state.gridPageData[Type]) {
                if(!state.gridPageData[Type][Status]) {
                    state.gridPageData[Type][Status] = {}
                }
                state.gridPageData[Type][Status]["page"] = gridPageData
                if(gridRowsInPageData) {
                    state.gridPageData[Type][Status]["rows"] = gridRowsInPageData
                }
            } else {
                state.gridPageData[Type] = {[Status]: { page: gridPageData, rows: gridRowsInPageData }}
            }
        },

        setIsFiltering(state, params) {
            const filter = params.filter
            const Type = params.Type
            const Status = params.Status
            let isFiltering = false
            for (const [key, value] of Object.entries(filter)) {
                if(filter[key].value) {
                    isFiltering = true;
                }
                if(filter[key].constraints) {
                    for (const constraint in filter[key].constraints) {
                        if(constraint.value) {
                            isFiltering = true;
                        }
                    }
                }

                if(isFiltering) {
                    break
                }
            }
            if(state.isFilteringGridData[Type]) {
                state.isFilteringGridData[Type][Status] = isFiltering
            } else {
                state.isFilteringGridData[Type] = {[Status]: isFiltering}
            }

        },
        setActiveStatus(state, params) {
            state.activeStatus = {
                Type: params.Type,
                Status: params.Status,
            }
        },
        setLoading(state, loading) {
            state.loading = loading
        },

    },
    actions: {
        async setDraftGridDataList ({context, state}, Status) {
            const res = await http.post("/form/GetTemplateFormList", {"Status": 0})
            if(res.Success) {
                state.draftGridDataList = res.Data
            }
        },

        async getGridDataList ({commit, getters, state}, data) {
            const Type = data.Type
            const Status = data.Status
            let res = null
            const pageData = getters.getGridPageDataByType(Type)
            const sortDataList = getters.getGridSortDataByType(Type)
            const filterData = getters.getGridFilterDataByType(Type)
            const page = data.page || pageData[Status].page || 0
            const sort = data.sort || sortDataList[Status] || {}
            const filter = data.filter || filterData[Status] || {}
            const rows = pageData[Status].rows || 20
            
            commit("setIsFiltering", {Type: Type, Status: Status, filter: filter})

            const DepartmentId = data.DepartmentId ? data.DepartmentId : ""
            if(Type == "Form") {
                res = await http.post("/form/GetPostedFormList", {
                    Status: Status, 
                    Page: page, 
                    Sort: sort, 
                    Filter: filter, 
                    DepartmentId: DepartmentId, 
                    DetailFormStatusId: data.DetailFormStatusId,
                    Rows: rows
                })
            } else if(Type == "Template") {
                res = await http.post("/form/GetTemplateFormList", {"Status": Status, Page: page, Sort: sort, Filter: filter, "DepartmentId": DepartmentId, Rows: rows})
            } else if(Type == "Sent") {
                res = await http.post("/form/GetSentFormList", {"Status": Status, Page: page, Sort: sort, Filter: filter, "DepartmentId": DepartmentId, Rows: rows})
            }

            return res
        },

        async fetchGridDataList ({commit, getters, state, dispatch}, data) {
            const Type = data.Type
            const Status = data.Status

            const res = await dispatch("getGridDataList", data)
            if(res && res.Success) {
                const insertData = {
                    Type: Type,
                    Status: Status,
                    GridData: res.Data
                }
                const index = state.gridDataList.findIndex(x => x.Type == Type && x.Status == Status)
                if(index != -1) {
                    state.gridDataList.splice(index, 1, insertData)
                } else {
                    state.gridDataList.push(insertData)
                }
                commit("setGridDataTotalRecord", {Type: Type, Status: Status, gridDataTotalRecord: res.TotalRecords})

                state.activeStatus = {
                    Type: Type,
                    Status: Status,
                }
            }
            commit("setSelectedGridDataList", [])
        },

        async reloadGridDataList({dispatch, state}) {
            const data = state.activeStatus
            const departmentId = router.currentRoute.value.params.departmentId
            const detailFormStatusId = router.currentRoute.value.params.detailFormStatusId
            data.DepartmentId = departmentId
            data.DetailFormStatusId = detailFormStatusId

            await dispatch("fetchGridDataList", data)
        },

        async updateTemplateRow({getters, state}, data) {
            let res = await http.post("/form/getTemplateRowById", {_id: data.id})
            const departmentId = router.currentRoute.value.params.departmentId
            if(res.Success) {
                const template = res.Data
                const data = state.activeStatus
                if(departmentId == constants.AllDepartmentIdConstants || departmentId == template.DepartmentId) {
                    const list = state.gridDataList.find(x => x.Type == data.Type && x.Status == data.Status)
                    const index = list.GridData.findIndex(x => x._id == template._id)
                    if(index != -1) {
                        list.GridData.splice(index, 1, template)
                    }
                } else {
                    res.needLoad = true
                }
            }
            return res
        },

        async updateFormRow({getters, state}, data) {
            const res = await http.post("/form/GetFormRowById", {_id: data.id})
            if(res.Success) {
                const form = res.Data
                const data = state.activeStatus
                const list = state.gridDataList.find(x => x.Type == "Form" && x.Status == data.Status)
                if(list) {
                    const index = list.GridData.findIndex(x => x._id == form._id)
                    if(index != -1) {
                        list.GridData.splice(index, 1, form)
                    }
                }
            }
        },

        async updateSentFormRow({getters, state}, data) {
            const res = await http.post("/form/GetSentFormRowById", {_id: data.id})
            if(res.Success) {
                const form = res.Data
                const data = state.activeStatus
                const list = state.gridDataList.find(x => x.Type == "Sent" && x.Status == data.Status)
                const index = list.GridData.findIndex(x => x._id == form._id)
                if(index != -1) {
                    list.GridData.splice(index, 1, form)
                }
            }
        },

        async bulkUpdateOperationForm({getters, state}, params) {
            const isSelectedAll = params.isSelectedAll
            const operation = params.operation
            const selectedForm = getters.getSelectedGridDataList
            const selectedFormId = selectedForm.map(x => x._id)
            const formStatus = state.activeStatus.Status

            const res = await http.post("/form/BulkUpdateOperationInForm", {isSelectedAll: isSelectedAll, selectedFormId, selectedFormId, operation: operation, formStatus: formStatus, detailFormStatusId: params.detailFormStatusId})

            return res
        },

        async bulkAcceptForm({getters, dispatch}, params) {
            const isSelectedAll = params.isSelectedAll
            const res = await dispatch("bulkUpdateOperationForm", {isSelectedAll: isSelectedAll, operation: "Accept", detailFormStatusId: params.detailFormStatusId})

            return res
        },

        async bulkRejectForm({getters, dispatch}, params) {
            const isSelectedAll = params.isSelectedAll
            const res = await dispatch("bulkUpdateOperationForm", {isSelectedAll: isSelectedAll, operation: "Reject", detailFormStatusId: params.detailFormStatusId})

            return res
        },

        async bulkStorageForm({getters, dispatch}, params) {
            const isSelectedAll = params.isSelectedAll
            const res = await dispatch("bulkUpdateOperationForm", {isSelectedAll: isSelectedAll, operation: "Storage", detailFormStatusId: params.detailFormStatusId})

            return res
        },

        async bulkDeleteForm({getters, dispatch}, params) {
            const isSelectedAll = params.isSelectedAll
            const res = await dispatch("bulkUpdateOperationForm", {isSelectedAll: isSelectedAll, operation: "Delete", detailFormStatusId: params.detailFormStatusId})

            return res
        },

        async createNewTemplateFormByImport({}, params) {
            const originalDepartmentUUID = params.originalDepartmentUUID
            const originalLinkUUID = params.originalLinkUUID
            const res = await http.post("/form/CreateNewTemplateFormByImport", {originalDepartmentUUID: originalDepartmentUUID, originalLinkUUID: originalLinkUUID})

            return res
        },

        async getPageOfTemplateForm({getters}, params) {
            const templateId = params.templateId
            const Type = "Template"
            const Status = constants.Status.Public
            const pageData = getters.getGridPageDataByType(Type)
            const sortDataList = getters.getGridSortDataByType(Type)
            const filterData = getters.getGridFilterDataByType(Type)
            const page = pageData[Status].page || 0
            const sort = sortDataList[Status] || {}
            const filter = filterData[Status] || {}
            const rows = pageData[Status].rows || 20


            const res = await http.post("/form/GetPageOfTemplateForm", {templateId: templateId, Page: page, Sort: sort, Filter: filter, Rows: rows})
            if(res.Success) {
                const Page = res.Page
                console.log(Page)
            }

            return res
        }
    }
}


