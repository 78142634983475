export default {
    name: 'constants',
    data() {
    },

    // local: "http://localhost:8080",
    // local: "https://app.paper.bankinvoice.com",
    // local: "https://192.168.1.14:8080",
    local: "https://test.paper.bankinvoice.com",
    // baseUrl: "http://localhost:3000",
    // baseUrl: "https://api.paper.bankinvoice.com",
    // baseUrl: "https://192.168.1.14:3000",
    baseUrl: "https://api.test.paper.bankinvoice.com",
    // baseUrl: "http://localhost:3001",


    Status: {
        "Draft": 0,
        "Public": 1,
        "Garbage": 2,
    },

    FormStatus: {
        "Received": 1,
        "Accept": 2,
        "Reject": 3,
        "Storage": 4,
        "Deleted": 5,
        "Dropped": 6,
    },

    SentStatus: {
        "Sent": 1,
    },

    successHelperLife: 30000,
    warningHelperLife: 30000,
    errorHelperLife: 30000,
    defaultToastGroup: "bl",
    validUploadFileExtList: ["doc","docx","docm","xls","xlsx","xlsm","ppt","pptx","pptm","rtf","jtd","jhd","jsd","odt","ods","odp","pdf","png","gif","jpg","jpeg","BMP","TIFF","mp3","mp4","MP3","MP4","wma","mov","jww","DXF","dwg","SFC","P21", "csv", "txt"],

    notFillRequireFieldArea : ["textField", "textAreaField", "dropDownField", "multiSelectField", "dateField", "timeField", "datetimeField"],
    timeFieldXtypes: ["dateField", "timeField", "datetimeField"],

    maxTextLength: 10000,

    ValidFunctionList: {
        CSVDownload: 0,
        CSVCreate: 1,
        EmailFormValid: 2,
        ConfirmSentForm: 3,
        ShowSentFormStatus: 4,
        WithdrawalSentForm: 5,
        NoteWithdrawalSentForm: 6,
        ConnectingAPI: 7,
        NoteSentForm: 8,
        MaxOpenEnd: 9,
    },

    maxFileNameLength: 100,

    AllDepartmentIdConstants: "@",

    DetailFormStatusIdConstants: "@",

    routeIndexStatus: {
        Top: 0,
        Draft: 1,
        PublicFormList: 2,
        Garbage: 3,
        ReceivedBox: 4,
        AcceptBox: 5,
        RejectedBox: 6,
        StorageBox: 7,
        Deleted: 8,
        DepartmentSetting: 9,
        InviteUser: 10,
        ChangeRegistrationInfo: 11,
        AllDepartmentUser: 12,
        CreateOrg: 13,
        CreateDepartment: 14,
        Dropped: 15,
        SentEmailView: 16,
        Setting: 17,
        Sent: 18,
    },


    DefaultFormListRows: 10,
    rowsPerPageOptions: [10, 50, 100],
    imageExtList: ["jpg", "jpeg", "png", "gif"],
    imageExtSet: new Set(["jpg", "jpeg", "png", "gif"]),
    invalidFileNameLetter: '\\/~"#%&*:<>?{}.|',
    maxCardTitleLength: 10000,
    maxCardContentLength: 10000,

    tiptapEditorsColorList: [
        {
            labelValue: "rgb(73, 80, 87)",
            value: "rgba(73, 80, 87, 0.4)",
        },
        {
            labelValue: "rgb(255,255,255)",
            value: "rgba(255,255,255, 0.4)",
        },
        {
            labelValue: "rgb(151, 72, 6)",
            value: "rgba(151, 72, 6, 0.4)",
        },
        {
            labelValue: "rgb(255, 0, 0)",
            value: "rgba(255, 0, 0, 0.4)",
        },
        {
            labelValue: "rgb(255, 192, 0)",
            value: "rgba(255, 192, 0, 0.4)",
        },
        {
            labelValue: "rgb(255, 255, 0)",
            value: "rgba(255, 255, 0, 0.4)",
        },
        {
            labelValue: "rgb(146, 208, 80)",
            value: "rgba(146, 208, 80, 0.4)",
        },
        {
            labelValue: "rgb(0, 176, 80)",
            value: "rgba(0, 176, 80, 0.4)",
        },
        {
            labelValue: "rgb(0, 176, 240)",
            value: "rgba(0, 176, 240, 0.4)",
        },
        {
            labelValue: "rgb(0, 112, 192)",
            value: "rgba(0, 112, 192, 0.4)",
        },
        {
            labelValue: "rgb(0, 32, 96)",
            value: "rgba(0, 32, 96, 0.4)",
        },
        {
            labelValue: "rgb(112, 48, 160)",
            value: "rgba(112, 48, 160, 0.4)",
        },
    ],
    tiptapEditorsFontColorList: [
        {
            value: "rgb(73, 80, 87)",
        },
        {
            value: "rgb(255,255,255)",
        },
        {
            value: "rgb(151, 72, 6)",
        },
        {
            value: "rgb(255, 0, 0)",
        },
        {
            value: "rgb(255, 192, 0)",
        },
        {
            value: "rgb(255, 255, 0)",
        },
        {
            value: "rgb(146, 208, 80)",
        },
        {
            value: "rgb(0, 176, 80)",
        },
        {
            value: "rgb(0, 176, 240)",
        },
        {
            value: "rgb(0, 112, 192)",
        },
        {
            value: "rgb(0, 32, 96)",
        },
        {
            value: "rgb(112, 48, 160)",
        },
    ],
    tiptapEditorsFontFamilyList: [
        {
            label: "Default",
            value: "Default",
        },
        {
            label: "Inter",
            value: 'Inter',
        },
        {
            label: "Comic Sans MS, Comic Sans" ,
            value: 'Comic Sans MS, Comic Sans',
        },
        {
            label: "serif" ,
            value: 'serif',
        },
        {
            label: "monospace" ,
            value: 'monospace',
        },
        {
            label: "cursive" ,
            value: 'cursive',
        },
    ],
    tiptapEditorsFontSizeList: [
        {
            label: "Large",
            value: 20,
        },
        {
            label: "Large-Medium",
            value: 18,
        },
        {
            label: "Medium",
            value: 16,
        },
        {
            label: "Normal",
            value: 14,
            isDefault: true
        },
        {
            label: "Medium-Small",
            value: 12,
        },
        {
            label: "Small",
            value: 10,
        },
    ],
}
