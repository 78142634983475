import constants from "@/mixins/constants";


export default {
    namespaced: true,
    state: {
        title: "",
        textList: [{}],
        show: false,
    },

    getters: {
        getTitle(state) {
            return state.title
        },
        getTextList(state) {
            return state.textList
        },
        getShow(state) {
            return state.show
        },
    },

    mutations: {
        setTitle(state, title) {
            state.title = title
        },
        setTextList(state, textList) {
            state.textList = textList
        },
        setShow(state, show) {
            state.show = show
        },
    },

    actions: {
    },
}


